import React, {useEffect, useState} from "react";
import {useStateContext} from "../../contexts/ContextProvider";
import {BrowserRouter} from "react-router-dom";
import {Colors} from "../../styles";
import {Assets} from "../../assets";
import {AccountStore} from "../../stores/account/accountStore";
import {Logger} from "../../utils";
import ReactLoading from "react-loading";

export const SignIn = () => {
	const {setCurrentColor, setCurrentMode} = useStateContext();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(false);
	const [loadingButton, setLoadingButton] = useState(false);

	useEffect(() => {
		const currentThemeColor = localStorage.getItem('colorMode');
		const currentThemeMode = localStorage.getItem('themeMode');
		if (currentThemeColor && currentThemeMode) {
			setCurrentColor(currentThemeColor);
			setCurrentMode(currentThemeMode);
		}
	}, []);

	const requestSignIn = async () => {
		try {
			const signinResponse = await AccountStore().signIn({email, password});
			setTimeout(() => {
				window.location.reload();
				Logger('login', signinResponse, null, 'run');
			}, 1000);
		} catch (e) {
			setError(true);
			setLoadingButton(false);
			throw `error.loginScreen.requestLogin : ${e}`;
		}
	}

	return (
		<div className="h-full">
			<BrowserRouter>
				<div
					style={styles.content}
					className="flex relative h-full w-full">
					<div style={styles.container}
							 className="flex relative h-full w-full banner"
					>
						<div style={{position: 'absolute', top: '30%', left: '10%'}}>
							<h2
								style={styles.disclaimer}
								className="mt-6 text-3xl tracking-tight font-bold text-gray-900">
								Mantenha sua casa limpa e refrescante!
							</h2>
							<p
								style={styles.disclaimer}
								className="mt-1 text-1xl tracking-tight text-gray-900">
								Acesse sua conta e seja o melhor que pode ser!
							</p>
						</div>
						<img
							className="mx-auto w-auto h-full"
							src={Assets.illustrations?.banner_signin}
							alt="Workflow"
						/>
					</div>
					<>
						<div style={styles.form}
								 className="min-h-full flex items-center justify-center py-12 px-4 sm:px-20 lg:px-8">
							<div className="max-w-md w-full space-y-10">
								<div>
									<img
										className="mx-auto h-20 w-auto"
										src={Assets.logo}
										alt="Workflow"
									/>

									<h2
										style={styles.title}
										className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
										Acesse sua conta!
									</h2>
									<div style={{height: 40, display: "flex", alignItems: "center", justifyContent: "center", marginBottom: -20}}>
										{error && <p
											className="text-center tracking-tight"
											style={{color: Colors.ambar}}>Erro ao acessar conta. Tente novamente mais trade!
										</p>}
									</div>
								</div>
								<form className="mt-8 space-y-6" method="POST" onSubmit={async (e) => {
									setLoadingButton(true);
									e.preventDefault();
									await requestSignIn();
								}}>
									<input type="hidden" name="remember" defaultValue="true"/>
									<div className="rounded-md shadow-sm -space-y-px">
										<div>
											<label htmlFor="email-address" className="sr-only">
												Email
											</label>
											<input
												id="email-address"
												name="email"
												type="email"
												autoComplete="email"
												onChange={(e) => {
													setError(false);
													setEmail(e.target.value)
												}}
												required
												className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
												placeholder="Email address"
												value={email}
											/>
										</div>
										<div>
											<label htmlFor="password" className="sr-only">
												Senha
											</label>
											<input
												id="password"
												name="password"
												type="password"
												autoComplete="current-password"
												onChange={(e) => {
													setError(false);
													setPassword(e.target.value);
												}}
												required
												className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
												placeholder="Password"
												value={password}
											/>
										</div>
									</div>
									<div className="flex items-center justify-between">
										<div className="flex items-center">
											{/*TODO: if use remember password*/}
											{/*<input*/}
											{/*	id="remember-me"*/}
											{/*	name="remember-me"*/}
											{/*	type="checkbox"*/}
											{/*	className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"*/}
											{/*/>*/}
											{/*<label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">*/}
											{/*	Remember me*/}
											{/*</label>*/}
										</div>
										<div className="text-sm">
											{/*<a href="#"*/}
											{/*	 style={styles.forgot}*/}
											{/*	 className="font-medium text-indigo-600 hover:text-indigo-500">*/}
											{/*	Forgot your password?*/}
											{/*</a>*/}
										</div>
									</div>
									<div>
										<button
											type="submit"
											style={styles.button}
											className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{
												loadingButton ?
													(<div style={styles.loading}>
														<ReactLoading type={'bubbles'} color={Colors.light} height={'80%'} width={'80%'}/>
													</div>) :
													<p>Entrar</p>
											}
										</button>
									</div>
								</form>
							</div>
						</div>
					</>
				</div>
			</BrowserRouter>
		</div>
	);
}
const styles = {
	content: {
		flex: 1,
		height: '100%',
		flexDirection: "row",
		backgroundColor: Colors.green_dark,
	},
	container: {
		flex: 0.8,
		overflow: 'hide',
		height: '100%',
		flexDirection: "column",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "center",
		marginLeft: 0,
		marginTop: 0,
	},
	form: {
		flex: 1,
		alignItems: "center",
		alignContent: "center",
		justifyContent: "center",
	},
	disclaimer: {
		color: Colors.black,
	},
	title: {
		color: Colors.light,
	},
	button: {
		marginTop: 30,
		height: 40,
		alignItems: "center",
		alignContent: "center",
		justifyContent: "center",
		backgroundColor: Colors.primary,
		...Colors.shadowDefault,
	},
	forgot: {
		color: Colors.light,
	},
	loading: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center',
		height: 100,
		width: 100,
	},
} as const;
