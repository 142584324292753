import {makeAutoObservable} from "mobx";
import * as ComboProvider from '../../services/combo';
import * as PubSub from '../pubSub';
import {Manager} from "../../core";
import {PlanStore} from "../plan/planStore";
import {BATHROOM, BEDROOM, EditRoomsComboProps, EditTimeComboProps, MIDDLE, UpdateItemComboProps} from "../../models";
import {AccountStore} from "../account/accountStore";
import {smallerThenToday, weekdayToday} from "../../utils";

export class ComboStoreMobx {
	public list: any[] = [];
	public combo: any;
	public extras: number = 0;
	constructor() {
		makeAutoObservable(this);
	}


	get = async () => {
		try {
			const {data}: any = await ComboProvider.get();
			let newData = data.map((e: any) => {
				e.hour = this.changeHour(e.hour);
				return e;
			});
			this.setComboList(newData);
			return newData;
		} catch (err) {
			throw new Manager.ServiceError('error.comboStore.get', err);
		}
	}

	save = async () => {
		try {
			PlanStore().setPlan({value: PlanStore().getPlanValue()});
			const {data}: any = await ComboProvider.post(PlanStore().plan);
			return data;
		} catch (err) {
			throw new Manager.ServiceError('error.comboStore.save', err);
		}
	}

	editTime = async (payload: EditTimeComboProps): Promise<boolean> => {
		const props: EditTimeComboProps = {
			...payload,
			id: this.combo?.id,
			userId: AccountStore().getUserId()
		};
		try {
			await ComboProvider.editTime(props);
			PubSub.publish('LOAD_COMBO_LIST');
			this.setCombo({...props});
			return true;
		} catch (err) {
			throw new Manager.ServiceError('error.comboStore.editTime', err);
		}
	}

	editRooms = async (payload: EditRoomsComboProps): Promise<boolean> => {
		const props: EditRoomsComboProps = {
			...payload,
			id: this.combo?.id,
			userId: AccountStore().getUserId(),
			value: this.getComboValue(payload),
		};
		try {
			this.setCombo({...props});
			await ComboProvider.editRooms(props);
			PubSub.publish('LOAD_COMBO_LIST');
			return true;
		} catch (err) {
			throw new Manager.ServiceError('error.comboStore.editRooms', err);
		}
	}

	update = async (payload: any): Promise<boolean> => {
		const props: UpdateItemComboProps = {
			id: this.combo?.id,
			userId: AccountStore().getUserId(),
			property: "often",
			value: payload.often,
		};
		try {
			await ComboProvider.update(props);
			PubSub.publish('LOAD_COMBO_LIST');
			this.setCombo({often: payload.often});
			return true;
		} catch (err) {
			throw new Manager.ServiceError('error.comboStore.update', err);
		}
	}

	updateState = async (combo: any): Promise<boolean> => {
		const props: UpdateItemComboProps = {
			...combo,
			property: "status",
		};
		try {
			await ComboProvider.update(props);
			this.setCombo({status: combo.status});
			return true;
		} catch (err) {
			throw new Manager.ServiceError('error.comboStore.cancel', err);
		}
	}

	setCombo = (item?: any) => {
		const userId = AccountStore().getUserId();
		this.combo = {
			...this.combo,
			userId,
			...item,
		};
	}

	setTotalCombosValue = () => {
		let total = 0;
		this.list.forEach(e => {
			if (e.status !== 'CANCELED' && e.weekday === weekdayToday() && smallerThenToday(e.date) ){
				total += Number(e.value)
			}
		});
		return String(total | 0);
	}

	setComboDetail = (combo: any) => {
		this.combo = combo;
	}

	setComboList = (list: any) => {
		this.list = list;
	}

	changeHour = (time: any) => {
		if (time === "01:00pm") {
			return "13:00pm";
		} else if (time === "02:00pm") {
			return "14:00pm";
		} else if (time === "04:00pm") {
			return "16:00pm";
		} else if (time === "06:00pm") {
			return "18:00pm";
		} else {
			return time;
		}
	}

	getComboValue = (payload: EditRoomsComboProps) => {
		const { bathrooms, bedrooms, middle } = payload;
		const { plan } = this.combo;
		let countBathrooms, countBedrooms, countMiddle;

		countBathrooms = bathrooms! > 0 ? (bathrooms! - 1) * BATHROOM : 0;
		countBedrooms = bedrooms! > 0 ? (bedrooms! - 1) * BEDROOM : 0;
		countMiddle = middle! > 0 ?  (middle! - 1) * MIDDLE : 0;

		return String(PlanStore().getValue(plan) + Number(countBathrooms + countBedrooms + countMiddle));
	}

}

let singleton: ComboStoreMobx | null;

export const ComboStore = () => {
	if (!singleton) {
		singleton = new ComboStoreMobx();

		PubSub.subscribe(PubSub.LOGOUT, () => {
			singleton = null;
		});
	}
	return singleton;
};
