import React from 'react';
import ReactLoading from 'react-loading';
import {Colors} from "../../styles";

interface LoadingProps {
	type?: any;
	color?: string;
	backgroundColor?: string;
}

const Loading = ({type, color, backgroundColor}: LoadingProps) => {
	const styles = {
		container: {
			flex: 1,
			display: 'flex',
			height: '100%',
			backgroundColor: backgroundColor || Colors.green_dark,
			justifyContent: 'center',
			alignItems: 'center',
		}
	}

	return (
		<div style={styles.container}>
			<ReactLoading type={type || 'bubbles'} color={color || Colors.light} height={'10%'} width={'10%'}/>
		</div>
	);
};

export default Loading;
