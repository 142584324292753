import {makeAutoObservable} from "mobx";
import * as PlansProvider from '../../services/plans';
import * as PubSub from '../pubSub';
import * as Models from "../../models";
import {Manager} from "../../core";
import * as Init from "../../models/initialState/plan";
import {BATHROOM, BEDROOM, MIDDLE, time} from "../../models/initialState";

export class PlanStoreMobx {
	public count: number = 0;
	public bathrooms: number = 1;
	public bedrooms: number = 1;
	public middle: number= 1;
	public extras: number = 0;
	public list: Models.PlanItemModel[] = [];
	protected provider = PlansProvider;
	public plan: Models.SetPlanRequest = Init.setPlanRequest;

	constructor() {
		makeAutoObservable(this);
	}

	get = async () => {
		try {
			const {data}: Models.PlanModel | any = await this.provider.get();
			this.setPlanList(data);
			return data;
		} catch (err) {
			throw new Manager.ServiceError('error.comboStore.getCombo', err);
		}
	}

	save = async (payload: Models.PlanRequest) => {
		try {
			const {data}: Models.PlanModel | any = await this.provider.post(payload);
			return data;
		} catch (err) {
			throw new Manager.ServiceError('error.comboStore.createCombo', err);
		}
	}

	setPlanList = (list: Models.PlanItemModel[]) => {
		this.list = list;
	}

	getValue = (plan: string) => {
		 const value =  this.list.filter(e => {
			if (e.plan === plan.toUpperCase()) {
				return e.value
			}
		});
		return Number(value[0].value);
	}

	setPlan = (item?: any) => {
		this.plan = {
				...this.plan,
				...item,
			};
	}

	setBedroom = (count: number) => {
		if (count >= 2) {
			this.extras += (count - 1) * BEDROOM;
		}
		if (count < this.bedrooms && count !== 0) {
			this.extras -= count * BEDROOM;
		}
		this.bedrooms = count === 0 ? 0 : count;
	}

	setBathroom = (count: number) => {
		if (count >= 2) {
			this.extras += (count - 1) * BATHROOM;
		}

		if (count < this.bathrooms && count !== 0) {
			this.extras -= count * BATHROOM;
		}
		this.bathrooms = count === 0 ? 0 : count;
	}

	setMiddle = (count: number) => {
		if (count >= 2) {
			this.extras += (count - 1) * MIDDLE;
		}

		if (count < this.middle && count !== 0) {
			this.extras -= count * MIDDLE;
		}
		this.middle = count === 0 ? 0 : count;
	}

	getPlanValue = () => {
		return Number(this.plan.value) + this.extras;
	}

	resetHour = () => {
		time.map(e => e.selected = false);
	}

	resetPlan = () => {
		this.count = 0;
		this.bathrooms = 0;
		this.bedrooms = 0;
		this.middle = 0;
		this.extras = 0;
	 	this.plan = Init.setPlanRequest;
	 	this.resetHour();
	}
}


let singleton: PlanStoreMobx | null;

export const PlanStore = () => {
	if (!singleton) {
		singleton = new PlanStoreMobx();

		PubSub.subscribe(PubSub.LOGOUT, () => {
			singleton = null;
		});
	}
	return singleton;
};
