import React, {useEffect, useState} from 'react';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page } from '@syncfusion/ej2-react-grids';

import { feedbackGrid } from './styles';
import {Header, Loading} from '../../components';
import {FeedbackStore} from "../../stores/feedback/feedbackStore";
import {Manager} from "../../core";
import {NotificationsStore} from "../../stores/notifications/notificationsStore";
import {Colors} from "../../styles";

export const Campaign = () => {
  const toolbarOptions = ['Search'];
  const [list, setList] = useState(NotificationsStore().list);
  const [loading, setLoading] = useState(false);

  const requestNotifications = async () => {
    setLoading(true);
    try {
      await NotificationsStore().get();
      setList(NotificationsStore().list);
    } catch (e) {
      Manager.RequestError('error.campaignView.get', e)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    requestNotifications().then((r) => r);
  }, []);


  const editing = { allowDeleting: true, allowEditing: true };

  return loading ? (
    <div style={{position: 'absolute', width: '100%', height: '100%'}}>
      <Loading backgroundColor={Colors.light} type={'bubbles'} color={Colors.green_darker} />
    </div>
  ) : (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Pagina" title="Campanhas" />
      <GridComponent
        dataSource={list}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5 }}
        editSettings={editing}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {feedbackGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page]} />

      </GridComponent>
    </div>
  );
};
