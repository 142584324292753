import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {DropDownListComponent} from '@syncfusion/ej2-react-dropdowns';
import { data } from '../Calendar/data';
import { Loading} from '../../components';
import * as C from './components';
import {
  dropdownData,
} from './styles';
import {Colors} from "../../styles";
import {ComboStore} from "../../stores/combo/comboStore";
import {Manager} from "../../core";
import {CustomerStore} from "../../stores/customers/customersStore";
import {SchedulesStore} from "../../stores/schedules/schedulesStore";

//TODO: use to select users
export const DropDown = (currentMode: any) => {
  return (
    <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
      <DropDownListComponent id="time" fields={{text: 'Time', value: 'Id'}}
                             style={{border: 'none', color: `${(currentMode === 'Dark') && 'white'}`}} value="1"
                             dataSource={dropdownData} popupHeight="220px" popupWidth="120px"/>
    </div>
  );
};

export const Home = () => {
  const [loading, setLoading] = useState(false);
  const [scheduleObj, setScheduleObj] = useState();
  const [schedules, setSchedules] = useState(data);

  //TODO: may be i use this things
  const navigation = useNavigate();

  const onDragStart = (arg: { navigation: { enable: boolean; }; }) => {
    arg.navigation.enable = true;
  };

  type DateType =  "Day" | "Week" | "WorkWeek" | "Month" | "Year" | "Agenda" | "MonthAgenda" | "TimelineDay" | "TimelineWeek" | "TimelineWorkWeek" | "TimelineMonth" | "TimelineYear" | undefined

  useEffect( () => {
    setLoading(true)
    Promise.all([
      handlerSchedulesRequest(),
      ComboStore().get(),
      CustomerStore().get(),
      ComboStore().get(),
    ]).catch((err) => {
      Manager.RequestError('error.dashboard.promise', err);
    }).finally(() => {
      setLoading(false);
    })
  }, []);

  const handlerSchedulesRequest = useCallback(async () => {
    try {
      const response =  await SchedulesStore().get();
      const list = response.filter(e => e.Status !== 'CANCELED');
      setSchedules(list);
    } catch (e) {
      Manager.RequestError('error.calendar.handlerRequest', e);
    }
  }, []);


  return loading ? (
    <div style={{position: 'absolute', width: '100%', height: '100%'}}>
      <Loading backgroundColor={Colors.light} type={'bubbles'} color={Colors.green_darker} />
    </div>
  ) : (
    <div className="mt-24">
      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        <C.DailyAmount />
        <C.Statistics />
      </div>
      <div className="flex flex-wrap lg:flex-wrap justify-center ">
        <C.GenericNotifications />
        <C.AlertNotifications />
      </div>
      <C.NextSchedules schedules={schedules} callback={(date: React.SetStateAction<undefined>) => setScheduleObj(date)} />
    </div>
  );
};
