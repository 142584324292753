import {AssetsModel, MenuModel} from "../models";

const menu: MenuModel[] = [
  {
    location: require('./img/home-icon/home-icon.png'),
    route: '',
    on: true,
  },
  {
    location: require('./img/vaccum-icon/vaccum-icon.png'),
    route: 'ChangeDate',
    on: false,
  },

  {
    location: require('./img/notification-off/notification-off.png'),
    route: 'Notifications',
    on: false,
  },
  {
    location: require('./img/config-icon/config-icon.png'),
    route: 'ConfigMenuScreen',
    on: false,
  },
]

const configMenu = [
  {
    title: 'profile',
    text: 'modify your information accessing \nprofile screen.',
    key: Math.random(),
    route: 'EditUserDate',
  },
  {
    title: 'edit your location',
    text: 'inform your new location \nto change clean location.',
    key: Math.random(),
    route: 'EditUserAddress',
  },
  {
    title: 'logout',
    text: 'get out of the app and change \nanother account',
    key: Math.random(),
    route: 'Logout',
  },
];

export const Assets: AssetsModel = {
  avatar_user: require('./img/avatar-user/avatar-user.png'),
  location: require('./img/location/location.png'),
  done: require('./img/done/done.png'),
  change_box_on: require('./img/change-box-on/change-box-on.png'),
  change_box_off: require('./img/change-box-off/change-box-off.png'),
  right_icon: require('./img/right-icon/right-icon.png'),
  money: require('./img/money-icon/money-icon.png'),
  disabled: require('./img/disabled-icon/disabled-icon.png'),
  office: require('./img/office-icon/office-icon.png'),
  logo: require('./img/logo-icon/logo-icon.png'),
  arrowLeft: require('./img/arrow-left/arrow-left.png'),
  arrowRight: require('./img/arrow-right/arrow-right.png'),
  whatsApp: require('./img/whatsApp/whatsApp.png'),
  rate: require('./img/rate/rate.png'),
  home: require('./img/home-icon/home-icon.png'),
  home_red: require('./img/home-red-icon/home-red-icon.png'),
  star_gold: require('./img/star-gold/star_gold.png'),
  notification_on: require('./img/notification-on/notification-on.png'),
  notification_off: require('./img/notification-off/notification-off.png'),
  menu: menu,
  config: configMenu,
  illustrations: {
    banner_signin: require('./ilustration/banner-signin/banner-signin.png'),
    notification_empty: require('./ilustration/notification-empty/notification-empty.png'),
    signup_done: require('./ilustration/signup-done/signup-done.png'),
    recover_done: require('./ilustration/recover-done/recover-done.png'),
    signup_banner: require('./ilustration/signup-banner/signup-banner.png'),
    recover_banner: require('./ilustration/recover-banner/recover-banner.png'),
    order_done: require('./ilustration/order-done/order-done.png'),
    light: require('./ilustration/light/light.png'),
    regular: require('./ilustration/regular/regular.png'),
    deep: require('./ilustration/deep/deep.png'),
    banner_1: require('./ilustration/banner-promo-1/banner-promo-1.png'),
    banner_2: require('./ilustration/banner-promo-2/banner-promo-2.png'),
    banner_3: require('./ilustration/banner-promo-3/banner-promo-3.png'),
    create_order: require('./ilustration/create-order/create-order.png'),
    home:require('./ilustration/signin-banner/signin-banner.png'),
    empty: require('./ilustration/empty.png'),
    splash: require('./ilustration/splash-back/splash-back.png'),
    combo: require('./ilustration/combo-back/combo-back.png'),
  },
  loading_splash: require('./loading/loadingsplash.gif'),
}
