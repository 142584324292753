import {makeAutoObservable} from "mobx";
import * as SchedulesProvider from '../../services/schedules';
import * as PubSub from '../pubSub';
import {Manager} from "../../core";
import {smallerThenTomorrow, weekdayTomorrow} from "../../utils";
import {Colors} from "../../styles";
import {ComboStore} from "../combo/comboStore";

export class SchedulesStoreMobx {
	public list: any[] = [];
	public nextSchedules: any[] = [];
	private title: string = 'Thanks for the opportunity';
	private text: string = "Tomorrow we will leave your sweet home clean and fragrant.";
	constructor() {
		makeAutoObservable(this);
	}

	setAlertSent = async () => {
		const date = new Date();
		await localStorage.removeItem('alert_sent');
		await localStorage.setItem('alert_sent', JSON.stringify(date));
	}

	isAlertSent = (): boolean => {
		const date = new Date();
		let sent_date = '';
		const alert_sent: any = localStorage.getItem('alert_sent');
		const today = `${date.getDate()}${date.getMonth()}${date.getFullYear()}`;
		if (alert_sent) {
			const alert_date = new Date(JSON.parse(alert_sent));
			sent_date = `${alert_date.getDate()}${alert_date.getMonth()}${alert_date.getFullYear()}`;
		}
		const isAlertSent = today === sent_date

		if (isAlertSent) {
			return true;
		} else {
			localStorage.removeItem('alert_sent');
			return false;
		}
	}

	get = async () => {
		try {
			const {data}: any = await SchedulesProvider.get();
			let newData = data.map((e: any) => {
				e.hour = ComboStore().changeHour(e.hour);
				return e;
			});
			this.setMessageSchedule(newData);
			return this.setSchedule(newData);
		} catch (err) {
			throw new Manager.ServiceError('error.comboStore.get', err);
		}
	}

	setInterval = (interval?: string): { often: number; count: number } => {
		switch (interval) {
			case 'biweekly':
				return {
					often: 14,
					count: 0,
				};
			case 'weekly':
				return {
					often: 7,
					count: 0,
				};
			default:
				return {
					often: 1,
					count: 1,
				};
		}
	}

	setDate = (date: string, hours?: string) => {
		const d = date.split('/');
		const year = Number(d[0]);
		const month = Number(d[1]) - 1;
		const day = Number(d[2]);

		const h = hours?.substr(0, hours.length - 2).split(':');
		const hour = Number(h![0]);
		const min = Number(h![1]);

		return {
			start: new Date(year, month, day, hour, min).toISOString(),
			end: new Date(year, month, day, hour + 1, min).toISOString(),
		};
	}

	setSchedule = (data: SchedulesProvider.SchedulesItemModel[]) => {
		 return data.map((e) => {
			const OFTEN: string = 'DAILY';
			const INTERVAL: { often: number; count: number } = this.setInterval(e?.often);
			const START_TIME = this.setDate(e.date, e.hour).start;
			const END_TIME = this.setDate(e.date, e.hour).end;
			return {
				Id: e.id,
				Subject: `Combo contratado ${e.plan} - ${e.often} </br> Valor: $ ${e.value}`,
				Location: `${e.street}, ${e.number} - ${e.uf} - ${e.complement || ''} `,
				StartTime: START_TIME,
				EndTime: END_TIME,
				Description: `${e.bedrooms} quarto(s) | ${e.bathrooms} banheiro(s) | ${e.middle} 1/2 banheiro 
											</br> Obs.: ${e.ps || 'Nenhuma obs.'}
											</br> Solicitante: ${e.name}
											</br> ${e.cellphone}
											</br> ${e.fixphone}`,
				CategoryColor: Colors.green_light,
				Status: e.status,
				RecurrenceRule: `FREQ=${OFTEN};INTERVAL=${INTERVAL.often};COUNT=${INTERVAL.count};`
			}
		});
	}

	setMessageSchedule = (list: any) => {
		const tomorrow = weekdayTomorrow();
		let schedule: any[] = [];
		if (!list) return [];
		schedule = list.filter((e: any) => e.weekday === tomorrow && e.status !== 'CANCELED' && smallerThenTomorrow(e.date));

		this.nextSchedules = schedule.length > 0 ? schedule.map((e: any) => {
			return {
					userId: e.userId,
					date: e.date,
					weekday: e.weekday,
					plan: e.plan,
					title: this.title,
					text: this.text,
					key: e.fmcId,
				};
		}): [];
	}
}

let singleton: SchedulesStoreMobx | null;

export const SchedulesStore = () => {
	if (!singleton) {
		singleton = new SchedulesStoreMobx();

		PubSub.subscribe(PubSub.LOGOUT, () => {
			singleton = null;
		});
	}
	return singleton;
};
