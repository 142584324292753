import React, {useState} from "react";
import {Colors} from "../../../styles";
import {Logger} from "../../../utils";
import {NotificationsStore} from "../../../stores/notifications/notificationsStore";
import ReactLoading from "react-loading";
import {useNavigate} from "react-router-dom";

export const GenericNotifications = () => {
	const [title, setTitle] = useState('');
	const [text, setText] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const navigation = useNavigate();

	const validate = () => {
		return !!title && !!text;
	}

	const request = async () => {
		if (!validate()) {
			return;
		}
		setLoading(true);
		try {
			await NotificationsStore().send_generic({title, text});
			Logger('genericNotification','success', null,  'run');
			setText('')
			setTitle('');
			navigation('/campaign');
		} catch (e) {
			setError('Message is not sent, Please check if input correct information on form')
			throw `error.genericNotification.request : ${e}`;
		} finally {
			setLoading(false);
		}
	}


	return(
		<div className="md:w-400 flex flex-wrap justify-center">
			<div
				style={styles.content}
				className="md:w-800 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
				<div style={styles.container}>
					<h2
						className="mt-6 text-2xl tracking-tight font-bold text-gray-600 dark:text-gray-200">
						Envio de menssagem aos usuários
					</h2>
					<p
						className="mt-1 text-1xl tracking-tight text-gray-500 dark:text-gray-200">
						Todos os usuários receberão uma notificação push e uma notificação interna.
					</p>
					<form className="mt-8" method="POST" onSubmit={ async (e) => {
						e.preventDefault();
						await request();
					}}>
						<div className="rounded-md shadow-sm -space-y-px">
							<div>
								<label htmlFor="title" className="sr-only">
									Título
								</label>
								<input
									id="title"
									name="title"
									type="text"
									autoComplete="text"
									onChange={(e) => {
										setTitle(e.target.value)
										setError('')
									}}
									required
									className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
									placeholder="Título"
									value={title}
								/>
							</div>
							<div>
								<label htmlFor="message" className="sr-only">
									Mensagem
								</label>
								<textarea
									id="message"
									name="message"
									autoComplete="text"
									onChange={(e) => {
										setText(e.target.value);
										setError('');
									}}
									required
									className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
									placeholder="Mensagem"
									value={text}
								/>
							</div>
							{error && (<p style={{color: Colors.red}}>{error}</p>)}
						</div>
						<div>
							<button
								type="submit"
								style={styles.button}
								className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							>
								{
									loading ?
										(<div style={styles.loading}>
											<ReactLoading type={'bubbles'} color={Colors.light} height={'80%'} width={'80%'}/>
										</div>) :
										<p>Enviar</p>
								}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
const styles = {
	content: {
		display: 'flex',
		paddingBottom: 60,
		flexDirection: 'row',
		alignItems: "center",
		justifyContent: "center",
	},
	container: {
		flex: 1,
		display: "flex",
		paddingInline: 10,
		justifyContent: "center",
		flexDirection: 'column',
	},
	form: {
		flex: 1,
		alignItems: "center",
		alignContent: "center",
		justifyContent: "center",
	},
	disclaimer: {
		color: Colors.black,
	},
	title: {
		color: Colors.light,
	},
	loading: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center',
		height: 100,
		width: 100,
	},
	button: {
		marginTop: 30,
		height: 40,
		alignItems: "center",
		alignContent: "center",
		justifyContent: "center",
		backgroundColor: Colors.primary,
		...Colors.shadowDefault,
	},
	forgot: {
		color: Colors.light,
	},
} as const;
