import React from "react";
import {CustomerStore} from "../../../stores/customers/customersStore";

export const Statistics = () => {
	return (
		<div className="flex m-3 flex-wrap justify-center gap-1 items-center">
			{CustomerStore().getEarningData().map((item) => (
				<div key={item.title}
						 className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 h-50 p-4 pt-9 rounded-2xl ">
					<button
						type="button"
						style={{color: item.iconColor, backgroundColor: item.iconBg}}
						className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
					>
						{item.icon}
					</button>
					<p className="mt-3">
						<span className="text-lg font-semibold dark:text-gray-500">{item.amount}</span>
					</p>
					<p className="text-sm text-gray-400  mt-1">{item.title}</p>
				</div>
			))}
		</div>
	)
}
