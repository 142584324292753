import {AuthModel} from "../../models";
import * as AccountProvider from '../../services/account';
import {makeAutoObservable} from "mobx";

import * as PubSub from '../pubSub';
import {Manager} from "../../core";

export class AccountStoreMobx {
  public isSigning = false;
  public isLogged = false;
  public user?: AuthModel = {
    email: '',
    password: '',
  };
  public profile: any = {};

  constructor() {
    makeAutoObservable(this);
    this.initialize();
  }

  initialize = async () => {
    await this.getLoggedUser();
  };

  setAccessToken = async () => {
    let access_token: any = await localStorage.getItem('access_token');
    !!access_token && await Manager.setToken(access_token);
  }

  register = async (payload: AuthModel) => {
    try {
      const {data}: any = await AccountProvider.create(payload);
      const { access_token } = data[0];
      await this.getAuth(payload, access_token);
    } catch (e) {
      this.resetLogin();
      Manager.RequestError('error.accountStore.login', e);
    }
  }

  signIn = async (payload: AuthModel) => {
    try {
      const {data}: any = await AccountProvider.auth(payload);
      const { access_token } = data[0];
      await this.getAuth(payload, access_token);
      return true;
    } catch (e) {
      this.resetLogin();
      Manager.RequestError('error.accountStore.login', e);
      return false;
    }
  }

  getAuth = async (payload: AuthModel, access_token: string) =>  {
    if (!!access_token) {
      await Manager.setToken(access_token);

      this.user = payload;
      this.isSigning = true;
      this.isLogged = true;

      await localStorage.removeItem('login');
      await localStorage.setItem('login', JSON.stringify(payload.email));
      await localStorage.setItem('access_token', access_token);
      return;
    }
    this.resetLogin();
  }

  resetLogin = () => {
    this.isSigning = false
    this.isLogged = false;
  }

  logout = async () => {
    await localStorage.removeItem('login');
    await localStorage.removeItem('address');
    await localStorage.removeItem('auth_user');
    await localStorage.removeItem('access_token');
    await localStorage.removeItem('notification_count');
    window.location.reload();
  }

  async verifyLogin() {
    const store: string = await localStorage.getItem('login') || '';
    const data = JSON.parse(store);
    return !!data;
  }

  async getLoggedUser() {
    this.isLogged = await this.getUser();
    this.isLogged && await this.setAccessToken();
  }

  async getUser(): Promise<boolean> {
    const userLogged: string | null = await localStorage.getItem('auth_user');
    if (typeof userLogged === "string") {
      this.user = JSON.parse(userLogged);
      return !!this.user;
    }
    return false
  }

  getUserId() {
    return this.user?.id;
  }

  updateProfile = async (profile: any) => {
    this.profile = {
      ...this.profile,
      ...profile,
    }
    await this.setStoreProfile();
  }

  setStoreProfile = async () => {
    await localStorage.setItem('auth_user', JSON.stringify(this.profile));
  }

  setProfile = async (profile: any) => {
    const profileStore = await localStorage.getItem('auth_user');
    this.profile = !profileStore ? profile : JSON.parse(profileStore);
  }

}


let singleton: AccountStoreMobx | null;

export const AccountStore = () => {
  if (!singleton) {
    singleton = new AccountStoreMobx();

    PubSub.subscribe(PubSub.LOGOUT, () => {
      singleton = null;
    });
  }
  return singleton;
};
