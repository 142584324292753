const avatar2 = require("../../assets/img/avatar2.jpg");
const avatar3 = require("../../assets/img/avatar3.png");
const avatar4 = require("../../assets/img/avatar4.jpg");
const avatar = require("../../assets/img/avatar.jpg");

export const chatData = [
  {
    image: avatar2,
    message: 'Roman Joined the Team!',
    desc: 'Congratulate him',
    time: '9:08 AM',
    dotColor: 'red',
  },
  {
    image: avatar3,
    message: 'New message received',
    desc: 'Salma sent you new message',
    time: '11:56 AM',
    dotColor: 'red',
  },
  {
    image: avatar4,
    message: 'New Payment received',
    desc: 'Check your earnings',
    time: '4:39 AM',
    dotColor: 'red',
  },
  {
    image: avatar,
    message: 'Jolly completed tasks',
    desc: 'Assign her new tasks',
    time: '1:12 AM',
    dotColor: 'red',
  },
];
