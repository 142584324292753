import React, {useEffect, useState} from 'react';
import {
	GridComponent,
	ColumnsDirective,
	ColumnDirective,
	Page,
	Selection,
	Inject,
	Edit,
	Toolbar,
	Sort,
	Filter,
	ExcelExport,
	PdfExport,
	SearchSettingsModel,
} from '@syncfusion/ej2-react-grids';

import {Header, Loading} from '../../components';

import {Manager} from "../../core";
import {CustomerStore} from "../../stores/customers/customersStore";
import {Colors} from "../../styles";

const customerGridImage = (props: { CustomerImage: string | undefined; CustomerName: React.ReactNode; CustomerEmail: React.ReactNode; }) => {
	return (
		<div className="image flex gap-4">
			<img
				className="rounded-full w-10 h-10"
				src={props.CustomerImage}
				alt="employee"
			/>
			<div>
				<p>{props.CustomerName}</p>
				<p>{props.CustomerEmail}</p>
			</div>
		</div>);
};

const address = (props: any) => {
	return (
		<div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
			<p className="rounded-full h-3 w-3"/>
			<p>{props.street} - {props.number}</p>
		</div>)
};

export const customersGrid = [
	// {type: 'checkbox', width: '50'},
	// {
	// 	field: 'id',
	// 	headerText: 'User ID',
	// 	width: '100',
	// 	textAlign: 'Center',
	// 	isPrimaryKey: true,
	// },
	{
		field: 'name',
		headerText: 'Nome',
		width: '150',
		textAlign: 'Center'
	},
	{
		field: 'surname',
		headerText: 'Sobrenome',
		width: '150',
		format: 'yMd',
		textAlign: 'Center',
	},
	{
		field: 'email',
		headerText: 'Email',
		width: '200',
		textAlign: 'Center'
	},
	{
		field: 'cellphone',
		headerText: 'Celular',
		width: '150',
		textAlign: 'Center'
	},
	{
		field: 'fixphone',
		headerText: 'Residêncial',
		width: '150',
		textAlign: 'Center'
	},
	{
		field: 'city',
		headerText: 'Cidade',
		width: '150',
		textAlign: 'Center'
	},
	{
		template: address,
		headerText: 'Rua',
		width: '250',
		textAlign: 'Center'
	},
	{
		field: 'createdAt',
		headerText: 'Criado em',
		width: '200',
		format: 'yMd',
		textAlign: 'Center',
	},
];


export const Customers = () => {
	const selectionSettings = {persistSelection: true};
	const toolbarOptions = ['Search'];
	const editing = {allowDeleting: true, allowEditing: true};
	const { list } = CustomerStore();
	const [loading, setLoading] = useState(false);
	useEffect( () => {
		setLoading(true)
		const handlerRequest = async () => {
			try {
				await CustomerStore().get();
				setLoading(false);
			} catch (e) {
				setLoading(false);
				Manager.RequestError('error.customers.handlerRequest', e);
			}
		};

		handlerRequest().then((r) => r);
	}, []);


	return loading ? (
		<div style={{position: 'absolute', width: '100%', height: '100%'}}>
			<Loading backgroundColor={Colors.light} type={'bubbles'} color={Colors.green_darker} />
		</div>
	) : (
		<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
			<Header category="Pagina" title="Usuários"/>
			<GridComponent
				dataSource={list}
				enableHover={true}
				allowPaging
				allowExcelExport
				allowPdfExport
				pageSettings={{pageCount: 5}}
				// selectionSettings={selectionSettings}
				toolbar={toolbarOptions}
				// editSettings={editing}
				allowSorting
			>
				<ColumnsDirective>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					{customersGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
				</ColumnsDirective>
				<Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter, ExcelExport, PdfExport]}/>
			</GridComponent>
		</div>
	);
};
