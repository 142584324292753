import React, {useState} from "react";
import {Colors} from "../../styles";
import {moneyFormat} from "../../utils";
import {Manager} from "../../core";
import ReactLoading from "react-loading";
import {ComboStore} from "../../stores/combo/comboStore";

export const gridOrderPlan = (props: { StatusBg: any; plan: React.ReactNode; }) => {
  let color: string = Colors.green_light;
  if (props.plan === 'DEEP') {
    color = Colors.primary;
  }
  if (props.plan === 'REGULAR') {
    color = Colors.green_dark;
  }
  if (props.plan === 'LIGHT') {
    color = Colors.red;
  }

  return (<button
    type="button"
    style={{background: color, width: 80}}
    className="text-white py-1 capitalize px-2 rounded-2xl text-md"
  >
    {String(props.plan)}
  </button>);
};

const updateStatus = (props: { status:React.ReactNode, id: React.ReactNode; userId: React.ReactNode; }) => {
  const [loading, setLoading] = useState(false);
  const [statusCombo, setStatusCombo] = useState(props.status);
  const CANCELED = 'CANCELED';
  const START = 'start';
  return (<button
    type="button"
    disabled={false}
    style={{
      background: statusCombo === 'CANCELED' ? Colors.ambar : Colors.primary,
      width: 80,
      height: 40,
      borderRadius: 10,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    }}
    className="text-white py-1 px-2 capitalize text-md flex items-center gap-2"
    onClick={async () => {
      let newStatus = '';

      if (statusCombo === CANCELED) {
        newStatus = START;
      } else {
        newStatus = CANCELED;
      }

      const combo = {
        id: props.id,
        value: newStatus,
      }

      setLoading(true)
      try{
        const success = await ComboStore().updateState(combo);
        success && setStatusCombo(newStatus);
      } catch (e) {
        Manager.RequestError('error.orders.updateState');
      } finally {
        setLoading(false);
      }
    }}
  >
    {
      loading ?
        (<div style={{
          marginTop: -20,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}>
          <ReactLoading type={'bubbles'} color={Colors.light} height={'80%'} width={'80%'}/>
        </div>) :
        <p>{statusCombo}</p>
    }
  </button>);
};

export const gridOrderValueMode = (props: { StatusBg: any; value: React.ReactNode; }) => {
  return (<p
    className="py-1 px-2 capitalize rounded-2xl text-md"
  >
    {moneyFormat(props.value)}
  </p>);
};

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];
export const ordersGrid = [
  //TODO: will be used if add payment mode
  // {
  //   field: 'payment',
  //   headerText: 'Payment Mode',
  //   width: '150',
  //   template: gridOrderPaymentMode,
  //   textAlign: 'Center',
  // },
  {
    field: 'plan',
    headerText: 'Combo',
    width: '150',
    template: gridOrderPlan,
    textAlign: 'Center',
  },
  {
    field: 'value',
    headerText: 'Valor do Combo',
    width: '150',
    template: gridOrderValueMode,
    textAlign: 'Center',
  },
  {
    field: 'often',
    headerText: 'Frequência',
    width: '100',
    textAlign: 'Center',
  },
  {
    field: 'weekday',
    headerText: 'Dia da semana',
    format: 'C2',
    textAlign: 'Center',
    editType: 'numericedit',
    width: '100',
  },
  {
    field: 'status',
    headerText: 'Status',
    width: '150',
    template: updateStatus,
    textAlign: 'Center',
  },
  {
    field: 'hour',
    headerText: 'Horário',
    width: '100',
    textAlign: 'Center',
  },
  {
    field: 'date',
    headerText: 'Data início',
    width: '150',
    textAlign: 'Center',
  },
  {
    field: 'name',
    headerText: 'Solicitante',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
  },
  {
    field: 'email',
    headerText: 'E-mail',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
  },
  {
    field: 'city',
    headerText: 'Cidade',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
  },
  {
    field: 'updatedAt',
    headerText: 'Atualizado em',
    width: '170',
    textAlign: 'Center',
  },
  {
    field: 'id',
    headerText: 'Id',
    width: '100',
    editType: 'dropdownedit',
    textAlign: 'Center',
  },
];
