import PubSub from 'pubsub-js';

export const PUSH_NOTIFICATION_RECEIVED = 'PUSH_NOTIFICATION_RECEIVED';
export const LOGOUT = 'LOGOUT';

type SubscribeHandler = (data: any) => void;

export const subscribe = (channel: string, handler: SubscribeHandler) => {
    // creating a redirect handler because the PubSub.publish() sends
    // two arguments, channel and data, but we need only data.
    const token = PubSub.subscribe(channel, (_: any, data: any) => handler(data));
    return () => PubSub.unsubscribe(token);
};

export const publish = (channel: string, data?: any) => {
    PubSub.publish(channel, data);
};
