import React, {useEffect} from 'react';
import {AiOutlineMenu} from 'react-icons/ai';
import {RiNotification3Line} from 'react-icons/ri';
import {MdKeyboardArrowDown} from 'react-icons/md';
import {TooltipComponent} from '@syncfusion/ej2-react-popups';
import {Cart, Chat, Notification, UserProfile} from '.';
import {useStateContext} from '../contexts/ContextProvider';
import {Assets} from "../assets";
import {AccountStore} from "../stores/account/accountStore";
import {FeedbackStore} from "../stores/feedback/feedbackStore";

interface NavButtonProps {
	title: any, customFunc: () => void, icon?: React.ReactNode, color?: any, dotColor?: any
}

const NavButton = ({title, customFunc, icon, color, dotColor}: NavButtonProps) => {
	return (
		<TooltipComponent content={title} position="BottomCenter">
			<button
				type="button"
				onClick={() => customFunc()}
				style={{color}}
				className="relative text-xl rounded-full p-3 hover:bg-light-gray"
			>
      <span
				style={{background: dotColor}}
				className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
			/>
				{icon}
			</button>
		</TooltipComponent>);
};

const Navbar = () => {
	const {currentColor, activeMenu, setActiveMenu, handleClick, isClicked, setScreenSize, screenSize} = useStateContext();
	const user = AccountStore().profile;
	useEffect(() => {
		const handleResize = () => {
			return setScreenSize(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, [setScreenSize]);

	useEffect(() => {
		if (screenSize <= 900) {
			setActiveMenu(false);
		} else {
			setActiveMenu(true);
		}
	}, [screenSize, setActiveMenu]);

	const handleActiveMenu = () => {
		return setActiveMenu(!activeMenu);
	};

	return (
		<div className="flex justify-between p-2 md:ml-6 md:mr-6 relative" >

			<NavButton title="Menu" customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu/>}/>
			<div className="flex">
				{FeedbackStore().budgeNotification() && <NavButton title="Notificações" dotColor="rgb(254, 201, 15)" customFunc={() => handleClick('notification')}
										color={currentColor} icon={<RiNotification3Line/>}/>}
				<TooltipComponent content="Perfil" position="BottomCenter">
					<div
						className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
						onClick={() => handleClick('userProfile')}
					>
						<img
							className="rounded-full w-8 h-8"
							src={Assets.avatar_user}
							alt="user-profile"
						/>
						<p>
							<span className="text-gray-400 text-14">Olá,</span>{' '}
							<span className="text-gray-400 font-bold ml-1 text-14">
                {user.name}
              </span>
						</p>
						<MdKeyboardArrowDown className="text-gray-400 text-14"/>
					</div>
				</TooltipComponent>

				{isClicked.cart && (<Cart/>)}
				{isClicked.chat && (<Chat/>)}
				{isClicked.notification && (<Notification/>)}
				{isClicked.userProfile && (<UserProfile/>)}
			</div>
		</div>
	);
};

export default Navbar;
