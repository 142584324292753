import React, {useEffect, useState} from "react";
import {useStateContext} from "../../contexts/ContextProvider";
import {BrowserRouter} from "react-router-dom";
import {TooltipComponent} from "@syncfusion/ej2-react-popups";
import {FiSettings} from "react-icons/fi";
import {Footer, Navbar, Sidebar, ThemeSettings} from "../../components";
import {MainRoot} from "../../main/MainStack";
import {FeedbackStore} from "../../stores/feedback/feedbackStore";
import {Manager} from "../../core";

export const Dashboard = () => {
  const {setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings} = useStateContext();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  useEffect( () => {
    setLoading(true)
    const handlerRequest = async () => {
      try {
        await FeedbackStore().get();
        setLoading(false);
      } catch (e) {
        setLoading(false);
        Manager.RequestError('error.feedback.handlerRequest', e);
      }
    };
    handlerRequest().then((r) => r);
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{zIndex: '1000'}}>
            <TooltipComponent
              content="Settings"
              // position="Top"
            >
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{background: currentColor, borderRadius: '50%'}}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings/>
              </button>
            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar/>
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar/>
            </div>
          )}
          <div
            className={
              activeMenu
                ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar/>
            </div>
            <div>
              {themeSettings && (<ThemeSettings/>)}
              <MainRoot/>
            </div>
            <Footer/>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}
