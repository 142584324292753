import {makeAutoObservable} from "mobx";
import * as NotificationsProvider from '../../services/notifications';
import * as PubSub from '../pubSub';
import * as Models from "../../models";
import {Manager} from "../../core";
import {SchedulesStore} from "../schedules/schedulesStore";

export class NotificationsStoreMobx {
	public count: number = 0;
	public list: Models.NotificationsItemModel[] = [];
	public notificationsDetail: Models.NotificationsItemModel = {};
	protected provider = NotificationsProvider;

	constructor() {
		makeAutoObservable(this);
	}

	send_generic = async (payload: any) => {
		try {
			const {data}: any = await this.provider.send_push(payload);
			return data;
		} catch (err) {
			throw new Manager.ServiceError('error.notificationsStore.send_generic', err);
		}
	}

	send = async () => {
		try {
			const {data}: any = await this.provider.post(SchedulesStore().nextSchedules);
			return data;
		} catch (err) {
			throw new Manager.ServiceError('error.notificationsStore.send_generic', err);
		}
	}

	get = async () => {
		try {
			const {data}: Models.NotificationsModel | any = await this.provider.get();
			this.setNotificationList(data);
			await this.getCount();
			return data;
		} catch (err) {
			throw new Manager.ServiceError('error.notificationsStore.get', err);
		}
	}

	delete = async (payload: Models.NotificationRequest) => {
		try {
			const {data}: Models.NotificationsModel | any = await this.provider.del(payload);
			return data;
		} catch (err) {
			throw new Manager.ServiceError('error.notificationsStore.delete', err);
		}
	}

	setNotificationList = (list: Models.NotificationsItemModel[]) => {
		this.list = list;
	}

	setNotificationDetail = (detail: Models.NotificationsItemModel) => {
		this.notificationsDetail = detail;
	}

	setCount = async (): Promise<any> => {
			await localStorage.removeItem('notification_count');
			await localStorage.setItem('notification_count', JSON.stringify(this.list.length));
			await this.getCount();
	}

	getCount = async () => {
		const cache = await this._getCacheCount();
		const result = this.list.length - cache;
		this.count =  result <= 0? 0 : result > 9 ? 9 : result;
	}

	_getCacheCount = async (): Promise<number> => {
		const data: string | any = await localStorage.getItem('notification_count');
		return Number(JSON.parse(data)) || 0;
	}
}


let singleton: NotificationsStoreMobx | null;

export const NotificationsStore = () => {
	if (!singleton) {
		singleton = new NotificationsStoreMobx();

		PubSub.subscribe(PubSub.LOGOUT, () => {
			singleton = null;
		});
	}
	return singleton;
};
