import {Manager} from "../core";
import * as Models from "../models";


export const send_push = async (payload: any) => {
	try {
		const {data} = await Manager.request(
			'post',
			'/admin/notifications/send-push.php',
			{...payload}
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.notifications.send_push', err)
	}
}

export const post = async (payload: any) => {
	try {
		const {data} = await Manager.request(
			'post',
			'/admin/notifications/create.php',
			payload
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.notifications.send_push', err)
	}
}

export const get = async () => {
	try {
		const {data} = await Manager.request(
			'get',
			'/admin/notifications/list.php',
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.notifications.get', err)
	}
}

export const del = async (payload: Models.NotificationRequest) => {
	try {
		const {data} = await Manager.request(
			'delete',
			'/admin/notifications/delete.php',
			{...payload}
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.notifications.delete', err)
	}
}
