import {makeAutoObservable} from "mobx";
import * as FeedbackProvider from '../../services/feedback';
import * as PubSub from '../pubSub';
import {Manager} from "../../core";
import React from "react";
import {FeedbackModelResponse} from "../../services/feedback";
import {Assets} from "../../assets";

export class FeedbackStoreMobx {
	public list: FeedbackModelResponse[] = [];
	public previewList: any[] = [];
	constructor() {
		makeAutoObservable(this);
	}

	get = async () => {
		try {
			const {data}: any = await FeedbackProvider.get();
			this.setFeedbackList(data);
			!!data && this.setFeedbackPreview();
			return data;
		} catch (err) {
			throw new Manager.ServiceError('error.feedbackStore.get', err);
		}
	}

	update = async (id: number) => {
		try {
			const {data}: any = await FeedbackProvider.update(id);
			await this.get();
			return data;
		} catch (err) {
			throw new Manager.ServiceError('error.feedbackStore.update', err);
		}
	}

	budgeNotification = (): boolean => {
		return this.list.filter(e => e.status !== 'DONE').length > 0;
	}

	setFeedbackList = (list: any) => {
		this.list = list;
	}

	setFeedbackPreview = () => {
		this.previewList = this.list.map(e => {
			return {
				Avatar: Assets.avatar_user,
				Name: e.name,
				Email: e.email,
				Feedback: e.feedback,
				CellPhone: e.cellphone,
				FixPhone: e.fixphone,
				CreatedAt: e.createdAt,
				Id: e.id,
				Status: e.status,
			}
		})
	}

}

let singleton: FeedbackStoreMobx | null;

export const FeedbackStore = () => {
	if (!singleton) {
		singleton = new FeedbackStoreMobx();

		PubSub.subscribe(PubSub.LOGOUT, () => {
			singleton = null;
		});
	}
	return singleton;
};
