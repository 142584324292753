import React, { useState} from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useNavigate } from "react-router-dom";
import { Button } from '../index';
import { useStateContext } from '../../contexts/ContextProvider';
import {FeedbackStore} from "../../stores/feedback/feedbackStore";
import {Assets} from "../../assets";

const Notification = () => {
  const navigation = useNavigate();
  const { currentColor } = useStateContext();
  const [loading, setLoading] = useState(false);
  const { list } = FeedbackStore();

  return (
    <div className="nav-item absolute right-5 md:right-40 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <div className="flex gap-10">
          <p className="font-semibold text-lg dark:text-gray-200">Notificações</p>
          <p className="text-gray-500 text-sm dark:text-gray-200"> {!!list && list.length} novo(s)</p>
        </div>
        <Button icon={<MdOutlineCancel />} color="rgb(153, 171, 180)" bgHoverColor="light-gray" size="2xl" borderRadius="50%" />
      </div>
      <div className="mt-5 " style={{
        height: 300,
        overflow: "auto",
      }}>
        {list?.map((item, index) =>{
          if (item.status === 'DONE') {
            return <></>;
          }
          return (
            <div key={index} className="flex items-center leading-8 gap-5 border-b-1 border-color p-3">
              <img className="rounded-full h-10 w-10" src={Assets.avatar_user} alt={'image notification'} />
              <div>
                <p className="font-semibold dark:text-gray-200">{item.email}</p>
                <p className="text-gray-500 text-sm dark:text-gray-400"> {item.feedback} </p>
              </div>
            </div>
          )}
        )}
      </div>
      <div className="mt-5">
        <Button
          color="white"
          callback={() => navigation('/feedback')}
          bgColor={currentColor}
          text="Ver lista de Feedbacks"
          borderRadius="10px"
          width="full"
        />
      </div>
    </div>
  );
};

export default Notification;
