import { Manager } from './../core';
import * as Models from "../models";

export const get = async () => {
	try {
		const {data} = await Manager.request(
			'get',
			'/admin/user/list.php',
		);
		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.account.auth', err)
	}
}
