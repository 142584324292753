import { SetPlanRequest } from "../plan";

export const LIGHT = 'LIGHT';
export const REGULAR = 'REGULAR';
export const DEEP = 'DEEP';

export const BEDROOM = 30.00;
export const BATHROOM = 30.00;
export const MIDDLE = 30.00;

export const setPlanRequest: SetPlanRequest = {
		plan: LIGHT,
		value: '',
		often: '',
    weekday: '',
		date: '',
		hour: '',
		bedrooms: 1,
		bathrooms: 1,
		middle: 1,
		ps: '',
		zipcode: '',
		status: 'start',
}

export const time = [
	{hour: '09:30am', selected: false},
	{hour: '11:30am', selected: false},
	{hour: '01:30pm', selected: false},
	{hour: '02:30pm', selected: false},
	{hour: '03:30pm', selected: false},
	{hour: '04:30pm', selected: false},
];
