import React, {useEffect, useState} from "react";
import {Colors} from "../../../styles";
import {Logger} from "../../../utils";
import {NotificationsStore} from "../../../stores/notifications/notificationsStore";
import ReactLoading from "react-loading";
import {SchedulesStore} from "../../../stores/schedules/schedulesStore";

export const AlertNotifications = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [alertSent, setAlertSent] = useState(false);

	useEffect(() => {
			const alert_sent = SchedulesStore().isAlertSent();
			setAlertSent(alert_sent)
	}, [SchedulesStore, setAlertSent]);


	const request = async () => {
		setLoading(true);
		try {
			await NotificationsStore().send();
			await SchedulesStore().setAlertSent();
			setAlertSent(true);
			Logger('Users Notification', 'success', null, 'run');
		} catch (e) {
			setError("Message can't be sent, try again lather!")
			throw `error.userNotification.request : ${e}`;
		} finally {
			setLoading(false);
		}
	}

	const renderItem = () => {
		const { nextSchedules } = SchedulesStore();

		if (!nextSchedules.length || nextSchedules === undefined){
			return (
				<div style={styles.itemList}>
					<p className="mt-1 text-1xl tracking-tight text-gray-500">
						Nenhum agendamento para amanhã.
					</p>
				</div>
			)
		}

		return !!nextSchedules && nextSchedules.map((e, i)=> {
			return (
				<div
					key={i}
					style={styles.itemList}
					className="rounded-md shadow-sm border-gray-900"
				>
					{alertSent && <div style={styles.ok}>Ok</div>}
					<p style={{marginInline: 8}}>Dia: {e.weekday}</p>
					<p style={{marginInline: 8}}>Combo: {e.plan}</p>
				</div>
			);
		});

	}

	return (
		<div className="md:w-400 flex flex-wrap justify-center">
			<div
				style={styles.content}
				className="md:w-800 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
				<div style={styles.container}>
					<h2
						className="mt-6 text-2xl tracking-tight font-bold text-gray-600 dark:text-gray-200">
						Alerta de agenda!
					</h2>
					<p
						className="mt-1 text-1xl tracking-tight text-gray-500 dark:text-gray-200">
						Uma mensagem será enviada aos usuários que serão atendidos no proximo dia.
					</p>
					{error && (<p style={{color: Colors.red}}>{error}</p>)}
					<form className="mt-8" method="POST" onSubmit={async (e) => {
						e.preventDefault();
						await SchedulesStore().setAlertSent();
						await request();
					}}>
						<div style={{
							flex: 1,
							overflow: "auto",
							height: (SchedulesStore().nextSchedules.length === 0) ? 130 : 200}
						} className="-space-y-px">
							{renderItem()}
						</div>
						<div>
							<button
								disabled={!(SchedulesStore().nextSchedules.length > 0)}
								type="submit"
								style={styles.button}
								className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							>
								{
									loading ?
										(<div style={styles.loading}>
											<ReactLoading type={'bubbles'} color={Colors.light} height={'80%'} width={'80%'}/>
										</div>) :
										<p>Avisar aos usuários</p>
								}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
const styles = {
	content: {
		display: 'flex',
		paddingBottom: 60,
		flexDirection: 'row',
		alignItems: "center",
		justifyContent: "center",
	},
	container: {
		flex: 1,
		display: "flex",
		paddingInline: 10,
		justifyContent: "center",
		flexDirection: 'column',
	},
	form: {
		flex: 1,
		alignItems: "center",
		alignContent: "center",
		justifyContent: "center",
	},
	disclaimer: {
		color: Colors.black,
	},
	title: {
		color: Colors.light,
	},
	loading: {
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center',
		height: 100,
		width: 100,
	},
	button: {
		marginTop: 30,
		height: 40,
		alignItems: "center",
		alignContent: "center",
		justifyContent: "center",
		backgroundColor: Colors.primary,
		...Colors.shadowDefault,
	},
	forgot: {
		color: Colors.light,
	},
	itemList: {
		height: 80,
		flex: 1,
		display: "flex",
		flexDirection: "row",
		paddingInline: 30,
		alignContent: "center",
		alignItems: "center",
		justifyContent: "center",
	},
	ok: {
		width: 30,
		height: 30,
		borderRadius: 50,
		backgroundColor: Colors.green_light,
		position: 'absolute',
		marginRight: -250,
		color: Colors.light,
		paddingLeft: 3,
		paddingTop: 2.5,
	},
} as const;
