import { Manager } from './../core';
import * as Models from "../models";

export const auth = async (payload: Models.AuthModel) => {
	try {
		const {data} = await Manager.request(
			'post',
			'/admin/auth/user.php',
			{...payload},
			{
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			}
		);
		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.account.auth', err)
	}
}

export const create = async (payload: Models.RegisterModel) => {
	try {
		const {data} = await Manager.request(
			'post',
			'/admin/user/create.php',
			{...payload},
			{
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			}

		);
		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.account.create', err)
	}
}

export const get = async () => {
	try {
		const {data} = await Manager.request(
			'get',
			'/admin/user/list.php',
		);
		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.account.auth', err)
	}
}
