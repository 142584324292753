import React from 'react';

const Footer = () => {
  return (
    <div className="mt-24">
      <p className="dark:text-gray-200 text-gray-700 text-center m-20">
        © 2022 All rights reserved by clean4u.com
      </p>
    </div>);
};

export default Footer;
