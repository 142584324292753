import React from "react";
import {Route, Routes} from "react-router-dom";
import {
  Area,
  Bar,
  Calendar, ColorMapping,
  ColorPicker,
  Customers,
  Editor,
  Feedback,
  Campaign,
  Financial,
  Home,
  Kanban,
  Line,
  Orders,
  Pie,
  Pyramid,
  Stacked,
  Dashboard,
} from "../pages";
import {FiShoppingBag} from "react-icons/fi";
import {
  AiOutlineCalendar,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import {IoMdContacts, IoMdNotifications} from "react-icons/io";
import {RiContactsLine} from "react-icons/ri";

export const sideMenuRoutes = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'inicio',
        route: 'home',
        icon: <FiShoppingBag/>,
      },
    ],
  },
  {
    title: 'Contratos',
    links: [
      {
        name: 'combos',
        route: 'orders',
        icon: <AiOutlineShoppingCart/>,
      },
      {
        name: 'calendário',
        route: 'calendar',
        icon: <AiOutlineCalendar/>,
      },
    ],
  },

  {
    title: 'Pessoas',
    links: [
      {
        name: 'campanha',
        route: 'campaign',
        icon: <IoMdNotifications/>,
      },
      {
        name: 'feedback',
        route: 'feedback',
        icon: <IoMdContacts/>,
      },
      {
        name: 'usuários',
        route: 'customers',
        icon: <RiContactsLine/>,
      },
    ],
  },
];

export const MainRoot = () => {
  return (
    <Routes>
      <Route path="/" element={(<Home />)}/>
      {/*<Route path="/pay" element={(<Payments />)}/>*/}

      {/* dashboard  */}
      <Route path="/home" element={(<Home/>)}/>

      {/* pages  */}
      <Route path="/orders" element={<Orders/>}/>
      <Route path="/feedback" element={<Feedback/>}/>
      <Route path="/customers" element={<Customers/>}/>
      <Route path="/campaign" element={<Campaign/>}/>

      {/* apps  */}
      <Route path="/kanban" element={<Kanban/>}/>
      <Route path="/editor" element={<Editor/>}/>
      <Route path="/calendar" element={<Calendar/>}/>
      <Route path="/color-picker" element={<ColorPicker/>}/>

      {/* charts  */}
      <Route path="/line" element={<Line/>}/>
      <Route path="/area" element={<Area/>}/>
      <Route path="/bar" element={<Bar/>}/>
      <Route path="/pie" element={<Pie/>}/>
      <Route path="/financial" element={<Financial/>}/>
      <Route path="/color-mapping" element={<ColorMapping/>}/>
      <Route path="/pyramid" element={<Pyramid/>}/>
      <Route path="/stacked" element={<Stacked/>}/>
    </Routes>
  )
}
