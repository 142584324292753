import { Manager } from "../../core";
import * as Models from "../../models";


export const get = async () => {
	try {
		const {data} = await Manager.request(
			'get',
			'/admin/combo/list.php',
		);
		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.combo.get', err)
	}
}

export const post = async (payload: Models.SetPlanRequest) => {
	try {
		const {data} = await Manager.request(
			'post',
			'/admin/combo/create.php',
			{...payload}
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.combo.post', err)
	}
}

export const editTime = async (payload: Models.EditTimeComboProps) => {
	try {
		const {data} = await Manager.request(
			'put',
			'/admin/combo/update/date-hour.php',
			{...payload}
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.combo.editTime', err)
	}
}

export const editRooms = async (payload: Models.EditRoomsComboProps) => {
	try {
		const {data} = await Manager.request(
			'put',
			'/admin/combo/update/rooms.php',
			{...payload}
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.combo.editRooms', err)
	}
}


export const update = async (payload: Models.UpdateItemComboProps) => {
	try {
		const {data} = await Manager.request(
			'put',
			'/admin/combo/update/row.php',
			{...payload}
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.combo.put', err)
	}
}

export const del = async (payload: Models.DeleteComboRequest) => {
	try {
		const {data} = await Manager.request(
			'delete',
			'/admin/combo/delete.php',
			{...payload}
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.combo.delete', err)
	}
}
