import React, {useEffect, useState} from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  ContextMenuItem,
  ContextMenuItemModel,
  Edit,
  ExcelExport,
  Filter,
  GridComponent,
  Inject,
  Page,
  PdfExport,
  Resize,
  Sort
} from '@syncfusion/ej2-react-grids';

import {contextMenuItems, ordersGrid} from './data';
import {Header, Loading} from '../../components';
import {ComboStore} from "../../stores/combo/comboStore";
import {Manager} from "../../core";
import {Colors} from "../../styles";

export const Orders = () => {
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true };
  const { list } = ComboStore();
  const [loading, setLoading] = useState(false);

  useEffect( () => {
    setLoading(true)
    const handlerRequest = async () => {
      try {
        await ComboStore().get();
        setLoading(false);
      } catch (e) {
        setLoading(false);
        Manager.RequestError('error.orders.handlerRequest', e);
      }
    };

    handlerRequest().then((r) => r);
  }, []);


  return loading ? (
    <div style={{position: 'absolute', width: '100%', height: '100%'}}>
      <Loading backgroundColor={Colors.light} type={'bubbles'} color={Colors.green_darker} />
    </div>
    ):(
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Pagina" title="Combos" />
      <GridComponent
        id="gridcomp"
        dataSource={list}
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        toolbar={toolbarOptions}
        contextMenuItems={contextMenuItems as ContextMenuItem[] | ContextMenuItemModel[] | undefined}
        editSettings={editing}
      >
        <ColumnsDirective >
          {ordersGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />
      </GridComponent>
    </div>
  );
};
