import React, {useState} from "react";
import {Colors} from "../../styles";
import {Manager} from "../../core";
import ReactLoading from "react-loading";
import {NotificationsStore} from "../../stores/notifications/notificationsStore";

export const GridUpdate = (props: { id: React.ReactNode; }) => {
  const [loading, setLoading] = useState(false);
  return (<button
    type="button"
    disabled={false}
    style={{
      background:Colors.red,
      width: 65,
      height: 40,
      borderRadius: 10,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    }}
    className="text-white py-1 px-2 capitalize text-md flex items-center gap-2"
    onClick={async () => {
      setLoading(true)
      try{
        await NotificationsStore().delete({id: Number(props.id)});
        await NotificationsStore().get();
        window.location.reload();
      } catch (e) {
        Manager.RequestError('error.campaign.delete');
      } finally {
        setLoading(false);
      }
    }}
  >
    {
      loading ?
        (<div style={{
          marginTop: -20,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}>
          <ReactLoading type={'bubbles'} color={Colors.light} height={'80%'} width={'80%'}/>
        </div>) :
        <p>Delete</p>
    }
  </button>);
};

const gridFeedback = (props: { text: React.ReactNode; }) => {
  return (
    <div style={{flexWrap: 'nowrap', flexDirection: 'row', overflow: 'initial',}}>
      <p className="text-gray-500 text-sm dark:text-gray-400 lg:flex-nowrap"> {props.text} </p>
    </div>)
};

export const feedbackGrid = [
  {
    template: GridUpdate,
    headerText: '',
    width: '100',
    textAlign: 'Center'
  },
  {
    headerText: 'Id',
    width: '80',
    field: 'id',
    textAlign: 'Center'
  },
  {
    headerText: 'Título',
    field: 'title',
    width: '180',
    textAlign: 'Center',
  },
  {
    headerText: 'Mensagem',
    width: '200',
    textAlign: 'Center',
    template: gridFeedback,
  },
  {
    headerText: 'Criado em',
    field: 'createdAt',
    width: '200',
    format: 'yMd',
    textAlign: 'Center'
  },
];

