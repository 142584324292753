import {makeAutoObservable} from "mobx";
import * as CustomerProvider from '../../services/customers';
import * as PubSub from '../pubSub';
import {Manager} from "../../core";
import {MdOutlineSupervisorAccount} from "react-icons/md";
import {BsBoxSeam} from "react-icons/bs";
import {ComboStore} from "../combo/comboStore";
import React from "react";

export class CustomerStoreMobx {
	public list: any[] = [];
	constructor() {
		makeAutoObservable(this);
	}

	get = async () => {
		try {
			const {data}: any = await CustomerProvider.get();
			this.setCustomersList(data);
			return data;
		} catch (err) {
			throw new Manager.ServiceError('error.customersStore.get', err);
		}
	}

	setCustomersList = (list: any) => {
		this.list = list;
	}

	getEarningData = () => {
		return [
			{
				icon: <MdOutlineSupervisorAccount/>,
				amount: CustomerStore().list.length,
				percentage: '-4%',
				title: 'Usuários',
				iconColor: '#03C9D7',
				iconBg: '#E5FAFB',
				pcColor: 'red-600',
			},
			{
				icon: <BsBoxSeam/>,
				amount: ComboStore().list.length,
				percentage: '+23%',
				title: 'Combos',
				iconColor: 'rgb(255, 244, 229)',
				iconBg: 'rgb(254, 201, 15)',
				pcColor: 'green-600',
			},
		];
	}

}

let singleton: CustomerStoreMobx | null;

export const CustomerStore = () => {
	if (!singleton) {
		singleton = new CustomerStoreMobx();

		PubSub.subscribe(PubSub.LOGOUT, () => {
			singleton = null;
		});
	}
	return singleton;
};
