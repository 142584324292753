import {Manager} from "../core";
import * as Models from "../models";

export const get = async () => {
	try {
		const {data} = await Manager.request(
			'get',
			'/admin/plans/list.php',
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.combo.get', err)
	}
}

export const post = async (payload: Models.PlanRequest) => {
	try {
		const {data} = await Manager.request(
			'post',
			'/admin/plans/create.php',
			{...payload}
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.combo.get', err)
	}
}
