import React, {useCallback, useEffect, useState} from 'react';
import {configure} from "mobx";
import {Dashboard, Privacy, SignIn} from './pages';
import './App.css';
import {AccountStore} from "./stores/account/accountStore";
import {Colors} from "./styles";
import { Loading } from "./components";

configure({
	enforceActions: "never",
})
const App = () => {
	const [loggedUser, setLoggedUser] = useState('');
	const [loading, setLoading] = useState(true);

	const _handlerUserLogged = useCallback(
		async () => {
			// TODO : remove after tests
			const isLoggedUser: string | null = await localStorage.getItem('login');
			if (isLoggedUser !== null) {
				setLoggedUser('LOGGED');
				AccountStore().initialize().then(r => r);
			} else {
				setLoggedUser('LOGIN');
			}
			setTimeout(() => {
				setLoading(false)
			}, 1000)
		},
		[],
	);

	useEffect(() => {
		setLoading(true);
		_handlerUserLogged().then(r => {
		});
	}, [_handlerUserLogged]);

	const [privacy, setPrivacy] = useState(false);

	useEffect(() => {
		const search = window.location.toString();
		const query = search.includes("privacy");
		setPrivacy(query);
	}, [setPrivacy]);

	const _redirect = () => {
		if (privacy) {
			return <Privacy />;
		}
		if (!!loggedUser && loggedUser === 'LOGGED') {
			return <Dashboard />;
		}
		if (!!loggedUser && loggedUser === 'LOGIN') {
			return <SignIn />;
		}
	}

	return loading ?
		(
			<>
				<Loading type={'bubbles'} color={Colors.light} />
			</>
		)
		: (
		<>
			{_redirect()}
		</>
	);
};

export default App;
