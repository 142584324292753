import React, {SetStateAction} from "react";
import {
	Agenda,
	DragAndDrop,
	Inject,
	Resize,
	ScheduleComponent,
	ViewDirective,
	ViewsDirective
} from "@syncfusion/ej2-react-schedule";
import {Header} from "../../../components";

interface AgendaProps {
	schedules: any,
	callback: (item: React.SetStateAction<undefined>) => void
}

export const NextSchedules = ({schedules, callback}: AgendaProps) => {

	const onDragStart = (arg: { navigation: { enable: boolean; }; }) => {
		arg.navigation.enable = true;
	};

	type DateType =  "Day" | "Week" | "WorkWeek" | "Month" | "Year" | "Agenda" | "MonthAgenda" | "TimelineDay" | "TimelineWeek" | "TimelineWorkWeek" | "TimelineMonth" | "TimelineYear" | undefined

	return (
		<div className="flex flex-wrap justify-center gap-1 items-center">
			<div className="md:w-800 bg-white dark:text-gray-200 rounded-2xl p-2 m-3">
				<Header category="Agenda" title="Verifique os agendamentos para o período"/>
				<ScheduleComponent
					height="450px"
					ref={(schedule) => callback(schedule as unknown as SetStateAction<undefined>)}
					selectedDate={new Date()}
					eventSettings={{ dataSource: schedules }}
					dragStart={onDragStart}
					firstDayOfWeek={1}
				>
					<ViewsDirective>
						{ ['Agenda'].map((item) => <ViewDirective key={item} option={item as DateType} />)}
					</ViewsDirective>
					<Inject services={[Agenda, Resize, DragAndDrop]} />
				</ScheduleComponent>
			</div>
		</div>
	);
};

