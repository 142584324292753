export const Colors = {
  primary: '#5883bf',
  green_light: '#a5bf45',
  green_dark: '#60731e',
  green_darker: '#465944',
  silver_darker: '#646464',
  silver_dark: '#a8a8a9',
  silver_light: '#E8E8E8',
  light: '#FFFFFF',
  black: '#000000',
  transparent: 'transparent',
  red: '#bf4132',
  wine: '#732c24',
  pink: '#987ac7',
  cyan: '#543ff2',
  ambar: '#FCAF17',
  shadowPrimary: {
    shadowColor: '#5883bf',
    shadowOffset: {
     width: 0,
      height: 5,
    },
    shadowOpacity: 0.50,
    shadowRadius: 5.00,

    elevation: 20,
  },
  shadowGreenLight: {
    shadowColor: '#a5bf45',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.50,
    shadowRadius: 5.00,

    elevation: 20,
  },
  shadowRed: {
    shadowColor: '#bf4132',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.50,
    shadowRadius: 5.00,

    elevation: 20,
  },
  shadowPink: {
    shadowColor: '#987ac7',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.50,
    shadowRadius: 5.00,

    elevation: 20,
  },
  shadowSilverLight: {
    shadowColor: '#E8E8E8',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.50,
    shadowRadius: 5.00,

    elevation: 20,
  },
  shadowLight: {
    shadowColor: '#FFFFFF',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.50,
    shadowRadius: 5.00,

    elevation: 20,
  },
  shadowDefault: {
    shadowColor: '#000000',
    shadowOffset: {
     width: 0,
      height: 5,
    },
    shadowOpacity: 0.30,
    shadowRadius: 5.00,

    elevation: 20,
  },
} as const;
