import {MdOutlineSupervisorAccount} from "react-icons/md";
import {BsBoxSeam,BsCurrencyDollar, BsShield} from "react-icons/bs";
import {FiCalendar, FiCreditCard} from "react-icons/fi";
import React from "react";
import {TiTick} from "react-icons/ti";

const avatar2 = require("../../assets/img/avatar2.jpg");
const avatar3 = require("../../assets/img/avatar3.png");
const avatar4 = require("../../assets/img/avatar4.jpg");
const avatar = require("../../assets/img/avatar.jpg");

const moke = [
  {
    id: 19,
    userId: "2",
    plan: "LIGHT",
    value: "160",
    often: "weekly",
    weekday: "Thursday",
    date: "2022/09/01",
    hour: "11:30am",
    bedrooms: 2,
    bathrooms: 1,
    middle: 0,
    ps: "Dont move tv",
    payment: "CHARGE",
    status: "CANCELED",
    updatedAt: "2022-08-21 01:15:45"
  },
  {
    id: 2,
    userId: "2",
    plan: "LIGHT",
    value: "220",
    often: "biweekly",
    weekday: "Thursday",
    date: "2022/09/01",
    hour: "15:30pm",
    bedrooms: 2,
    bathrooms: 2,
    middle: 0,
    ps: "problem with frezer",
    payment: "CASH",
    status: "ORDER",
    updatedAt: "2022-08-14 00:22:45"
  }
];

export const weeklyStats = [
  {
    icon: <FiCalendar/>,
    amount: '-$560',
    title: 'Top Sales',
    desc: 'Johnathan Doe',
    iconBg: '#FB9678',
    pcColor: 'red-600',
  },
  {
    icon: <FiCalendar/>,
    amount: '-$560',
    title: 'Best Seller',
    desc: 'MaterialPro Admin',
    iconBg: 'rgb(254, 201, 15)',
    pcColor: 'red-600',
  },
  {
    icon: <FiCalendar/>,
    amount: '+$560',
    title: 'Most Commented',
    desc: 'Ample Admin',
    iconBg: '#00C292',
    pcColor: 'green-600',
  },
];

export const dropdownData = [
  {
    Id: '1',
    Time: 'March 2021',
  },
  {
    Id: '2',
    Time: 'April 2021',
  }, {
    Id: '3',
    Time: 'May 2021',
  },
];

export const SparklineAreaData = [
  {x: 1, yval: 2},
  {x: 2, yval: 6},
  {x: 3, yval: 8},
  {x: 4, yval: 5},
  {x: 5, yval: 10},
];


//todo: analysis if needed
export const medicalproBranding = {
  data: [
    {
      title: 'Due Date',
      desc: 'Oct 23, 2021',
    },
    {
      title: 'Budget',
      desc: '$98,500',
    },
    {
      title: 'Expense',
      desc: '$63,000',
    },
  ],
  teams: [
    {
      name: 'Bootstrap',
      color: 'orange',
    },
    {
      name: 'Angular',
      color: '#FB9678',
    },
  ],
  leaders: [
    {
      image:
      avatar2,
    },
    {
      image:
      avatar3,
    },
    {
      image:
      avatar2,
    },
    {
      image:
      avatar4,
    },
    {
      image:
      avatar,
    },
  ],
};
export const recentTransactions = [
  {
    icon: <BsCurrencyDollar/>,
    amount: '+$350',
    title: 'Paypal Transfer',
    desc: 'Money Added',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    pcColor: 'green-600',
  },
  {
    icon: <BsShield/>,
    amount: '-$560',
    desc: 'Bill Payment',
    title: 'Wallet',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
    pcColor: 'red-600',
  },
  {
    icon: <FiCreditCard/>,
    amount: '+$350',
    title: 'Credit Card',
    desc: 'Money reversed',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',

    pcColor: 'green-600',
  },
  {
    icon: <TiTick/>,
    amount: '+$350',
    title: 'Bank Transfer',
    desc: 'Money Added',

    iconColor: 'rgb(228, 106, 118)',
    iconBg: 'rgb(255, 244, 229)',
    pcColor: 'green-600',
  },
  {
    icon: <BsCurrencyDollar/>,
    amount: '-$50',
    percentage: '+38%',
    title: 'Refund',
    desc: 'Payment Sent',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    pcColor: 'red-600',
  },
];

export const ecomPieChartData = [
  {x: '2018', y: 18, text: '35%'},
  {x: '2019', y: 18, text: '15%'},
  {x: '2020', y: 18, text: '25%'},
  {x: '2021', y: 18, text: '25%'},
];
