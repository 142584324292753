import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import jwt_decode from "jwt-decode";
import {Logger} from "../utils";
import {AccountStore} from "../stores/account/accountStore";

export const REQUEST_TIMEOUT = 30000;
export const DEV = process.env.NODE_ENV === 'development';
const base_staging = 'http://localhost:8888';
const base_production = 'https://homeclean4u.net';
const api_v1 = 'api-clean4u/api/v1';

const logger = Logger;

export interface AxiosRequestModel {
	config?: AxiosRequestConfig;
	options?: any;
	mustRefreshToken?: boolean;
	skipLogData?: boolean;
	untrackedErrorCodes?: Array<number>;
}

interface Clean4uCfg extends AxiosRequestConfig {
	startTime: number;
}

const getResponseSuccessHandler =
	(config: { config?: AxiosRequestConfig<any>; method?: any; url?: any; }) => {
		return (response: AxiosResponse) => {
			if (DEV) {
				const duration = Date.now() - (response.config as Clean4uCfg)?.startTime || 0;
				logger(
					`${response.config?.method} ${response.config?.url} ${
						response.config?.params ? JSON.stringify(response.config?.params) : ''
					} in ${duration}ms\n\n`,
					response,
					null,
					'success'
				);
			}
			return response;
		};
	};

export const axiosRequest = <T>(requestProps: AxiosRequestModel): Promise<AxiosResponse<T>> => {
	const {
		config = {},
		options = {},
	} = requestProps;
	const instance = axios.create({
		timeout: REQUEST_TIMEOUT,
		...options,
	});

	if (DEV) {
		instance.interceptors.request.use(
			(request) => {
				(request as Clean4uCfg).startTime = Date.now();
				return request;
			},
			(error) => {
				logger('api.request error', error, null , 'error');
				Promise.reject(error);
			}
		);
	}

	instance.interceptors.response.use(
		getResponseSuccessHandler({ config }),
	);

	return instance.request(config);
};

export const request = (method: 'get' | 'post' | 'put' | 'patch' | 'delete'| 'options', url: string, data: any = null, options = {}, mustRefreshToken = true, skipLogData = false, untrackedErrorCodes = [], baseURL?: string) => {
	setBaseUrl(DEV);
	// setBaseUrl(false);
	return axiosRequest({
		config: {
			method,
			url,
			data,
			baseURL,
		},
		options,
		mustRefreshToken,
		skipLogData,
		untrackedErrorCodes,
	});
};

export const RequestError = (msn?: string, error?: AxiosError | any) => {
	throw `${msn} :: ${error}` ;
}

export class ServiceError extends Error {
	status?: number;
	data?: any;
	isNetworkError: boolean;
	constructor(source: string, axiosError: any) {
		super(`${source}: ${axiosError?.message}`);
		this.status = axiosError?.response?.status || 0;
		this.data = axiosError?.response?.data;
		this.isNetworkError =
			!!axiosError?.message?.includes('Network Error') ||
			!!axiosError?.message?.includes(`timeout of ${REQUEST_TIMEOUT}ms exceeded`);
		Object.setPrototypeOf(this, ServiceError.prototype);
	}
}

export const setToken = async (accessToken: string) => {
	let auth_user = jwt_decode(accessToken);
	const auth_user_store = await localStorage.getItem('auth_user');
	if (!auth_user_store) {
		await localStorage.setItem('auth_user', JSON.stringify(auth_user));
	}
	await AccountStore().setProfile(auth_user);
	return axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const setBaseUrl = (stagingMode: boolean) => {
	axios.defaults.baseURL = stagingMode
		? `${base_staging}/${api_v1}`
		: `${base_production}/${api_v1}`;
};
