export const data = [
  {
    Id: 0,
    Subject: '',
    Location: '',
    StartTime: '',
    EndTime: '',
    CategoryColor: '',
    Description: '',
    RecurrenceRule: 'FREQ=DAILY;INTERVAL=14;COUNT=6'
  },
];
