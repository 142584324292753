import { Manager } from "../../core";
import * as Models from "../../models";

export const get = async () => {
	try {
		const {data} = await Manager.request(
			'get',
			'/admin/feedback/list.php',
		);
		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.feedback.get', err)
	}
}

export const update = async (id: number) => {
	try {
		const {data} = await Manager.request(
			'put',
			'/admin/feedback/update.php',
			{id}
		);

		return data;
	} catch (err) {
		throw new Manager.ServiceError('error.feedback.put', err)
	}
}
