//https://ej2.syncfusion.com/react/documentation/schedule/getting-started/
import React, {SetStateAction, useEffect, useState} from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week,  Month, Agenda, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

import { data } from './data';
import {Header, Loading} from '../../components';
import {Manager} from "../../core";
import {SchedulesStore} from "../../stores/schedules/schedulesStore";
import {Colors} from "../../styles";

type DateType =  "Day" | "Week" | "WorkWeek" | "Month" | "Year" | "Agenda" | "MonthAgenda" | "TimelineDay" | "TimelineWeek" | "TimelineWorkWeek" | "TimelineMonth" | "TimelineYear" | undefined

const PropertyPane = (props: { children: any }) => {
  return <div className="mt-5">{props.children}</div>;
};

export const Calendar = () => {
  const [scheduleObj, setScheduleObj] = useState();
  const [schedules, setSchedules] = useState(data);
  const [loading, setLoading] = useState(false);
  const currentThemeColor = localStorage.getItem('colorMode');

  const change = (args: { value: any; }) => {
    // @ts-ignore
    scheduleObj.selectedDate = args.value;
    // @ts-ignore
    scheduleObj.dataBind();
  };

  const onDragStart = (arg: { navigation: { enable: boolean; }; }) => {
    arg.navigation.enable = true;
  };

  useEffect( () => {
    setLoading(true)
    const handlerRequest = async () => {
      try {
       const response =  await SchedulesStore().get();
       const list = response.filter(e => e.Status !== 'CANCELED');
        setSchedules(list);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        Manager.RequestError('error.calendar.handlerRequest', e);
      }
    };

    handlerRequest().then((r) => r);
  }, []);

  return loading ? (
    <div style={{position: 'absolute', width: '100%', height: '100%'}}>
      <Loading backgroundColor={Colors.light} type={'bubbles'} color={Colors.green_darker} />
    </div>
  ) : (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Pagina" title="Calendário" />
      <PropertyPane>
        <table
          style={{ width: '20%', background: 'white' }}
        >
          <tbody>
          <tr style={{ height: '50px' }}>
            <td style={{ width: '100%' }}>
              <DatePickerComponent
                value={new Date()}
                showClearButton={false}
                placeholder="Selecione ou digite a data"
                floatLabelType="Always"
                change={change}
              />
            </td>
          </tr>
          </tbody>
        </table>
      </PropertyPane>
      <ScheduleComponent
        height="650px"
        ref={(schedule) =>  setScheduleObj(schedule as unknown as SetStateAction<undefined>)}
        selectedDate={new Date()}
        eventSettings={{ dataSource: schedules }}
        dragStart={onDragStart}
        firstDayOfWeek={1}
      >
        <ViewsDirective>
          { ['Month','Week','Day','Agenda'].map((item) => <ViewDirective key={item} option={item as DateType} />)}
        </ViewsDirective>
        <Inject services={[Month, Week, Day, Agenda, Resize, DragAndDrop]} />
      </ScheduleComponent>
    </div>
  );
};

