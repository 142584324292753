import React, {useState} from "react";
import {Colors} from "../../styles";
import {FeedbackStore} from "../../stores/feedback/feedbackStore";
import {Manager} from "../../core";
import ReactLoading from "react-loading";

export const GridUpdate = (props: { Status:React.ReactNode, Id: React.ReactNode; }) => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(props.Status === 'DONE');
  return (<button
    type="button"
    disabled={done}
    style={{
      background:done ? Colors.silver_darker : Colors.green_light,
      width: 80,
      height: 40,
      borderRadius: 10,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    }}
    className="text-white py-1 px-2 capitalize text-md flex items-center gap-2"
    onClick={async () => {
      if (done) {
        return;
      }
      setLoading(true)
      try{
        await FeedbackStore().update(Number(props.Id))
        setDone(true);
      } catch (e) {
        Manager.RequestError('error.feedback.update');
      } finally {
        setLoading(false);
      }
    }}
  >
    {
      loading ?
        (<div style={{
          marginTop: -20,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}>
          <ReactLoading type={'bubbles'} color={Colors.light} height={'80%'} width={'80%'}/>
        </div>) :
        <p>{done ? 'Done' : 'Waiting'}</p>
    }
  </button>);
};

const gridFeedback = (props: { Feedback: React.ReactNode; }) => {
  return (
    <div style={{flexWrap: 'wrap', flexDirection: 'row', overflow: 'initial'}}>
      <p className="text-gray-500 text-sm dark:text-gray-400"> {props.Feedback} </p>
    </div>)
};

const gridEmployeeProfile = (props: { Avatar: string | undefined; Name: React.ReactNode; }) => {
  return (
    <div className="flex items-center gap-2">
      <img
        className="rounded-full w-10 h-10"
        src={props.Avatar}
        alt="user"
      />
      <p>{props.Name}</p>
    </div>);
};

export const feedbackGrid = [
  {
    headerText: 'Usuário',
    width: '250',
    template: gridEmployeeProfile,
    textAlign: 'Center'
  },
  {
    field: 'Name',
    headerText: 'Nome',
    width: '0',
    textAlign: 'Center',
  },
  {
    field: 'Email',
    headerText: 'E-mail',
    width: '180',
    textAlign: 'Center',
  },
  {
    template: gridFeedback,
    headerText: 'Feedback',
    width: '200',
    textAlign: 'Center',
  },
  {
    field: 'CellPhone',
    headerText: 'Celular',
    width: '150',
    textAlign: 'Center'
  },
  {
    field: 'FixPhone',
    headerText: 'Residêncial',
    width: '150',
    textAlign: 'Center'
  },
  {
    field: 'CreatedAt',
    headerText: 'Criado em',
    width: '200',
    format: 'yMd',
    textAlign: 'Center'
  },
  {
    field: 'Id',
    headerText: 'Id',
    width: '100',
    textAlign: 'Center'
  },
  {
    template: GridUpdate,
    headerText: 'Status',
    width: '150',
    textAlign: 'Center'
  },
];

