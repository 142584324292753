const areaChartData = [
  [
    {x: new Date(2002, 0, 1), y: 2.2},
    {x: new Date(2003, 0, 1), y: 3.4},
    {x: new Date(2004, 0, 1), y: 2.8},
    {x: new Date(2005, 0, 1), y: 1.6},
    {x: new Date(2006, 0, 1), y: 2.3},
    {x: new Date(2007, 0, 1), y: 2.5},
    {x: new Date(2008, 0, 1), y: 2.9},
    {x: new Date(2009, 0, 1), y: 3.8},
    {x: new Date(2010, 0, 1), y: 1.4},
    {x: new Date(2011, 0, 1), y: 3.1},
  ],
  [
    {x: new Date(2002, 0, 1), y: 2},
    {x: new Date(2003, 0, 1), y: 1.7},
    {x: new Date(2004, 0, 1), y: 1.8},
    {x: new Date(2005, 0, 1), y: 2.1},
    {x: new Date(2006, 0, 1), y: 2.3},
    {x: new Date(2007, 0, 1), y: 1.7},
    {x: new Date(2008, 0, 1), y: 1.5},
    {x: new Date(2009, 0, 1), y: 2.8},
    {x: new Date(2010, 0, 1), y: 1.5},
    {x: new Date(2011, 0, 1), y: 2.3},
  ],
  [
    {x: new Date(2002, 0, 1), y: 0.8},
    {x: new Date(2003, 0, 1), y: 1.3},
    {x: new Date(2004, 0, 1), y: 1.1},
    {x: new Date(2005, 0, 1), y: 1.6},
    {x: new Date(2006, 0, 1), y: 2},
    {x: new Date(2007, 0, 1), y: 1.7},
    {x: new Date(2008, 0, 1), y: 2.3},
    {x: new Date(2009, 0, 1), y: 2.7},
    {x: new Date(2010, 0, 1), y: 1.1},
    {x: new Date(2011, 0, 1), y: 2.3},
  ],
];

export const areaCustomSeries = [
  {
    dataSource: areaChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'USA',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',

  },
  {
    dataSource: areaChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'France',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',
  },
  {
    dataSource: areaChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Germany',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',
  },
];
export const areaPrimaryXAxis = {
  valueType: 'DateTime',
  labelFormat: 'y',
  majorGridLines: {width: 0},
  intervalType: 'Years',
  edgeLabelPlacement: 'Shift',
  labelStyle: {color: 'gray'},
};
export const areaPrimaryYAxis = {
  labelFormat: '{value}%',
  lineStyle: {width: 0},
  maximum: 4,
  interval: 1,
  majorTickLines: {width: 0},
  minorTickLines: {width: 0},
  labelStyle: {color: 'gray'},
};
