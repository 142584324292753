export const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const capitalize = (str: string) => {
	return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
}

export const nameFormat = (name: string) => {
	name = name.replace(/[^\'\.\^\~\´\`\\áÁ\\àÀ\\ãÃ\\âÂ\\éÉ\\èÈ\\êÊ\\íÍ\\ìÌ\\óÓ\\òÒ\\õÕ\\ôÔ\\úÚ\\ùÙ\\çÇaA-zZ\'\.\^\~\´\`\\áÁ\\àÀ\\ãÃ\\âÂ\\éÉ\\èÈ\\êÊ\\íÍ\\ìÌ\\óÓ\\òÒ\\õÕ\\ôÔ\\úÚ\\ùÙ\\çÇa A-zZ]+/g,"");
	return name.substr(0,1).toUpperCase() + name.substr(1).toLowerCase();
};

export const surnameFormat = (surname: string) => {
	let explode = surname.split(" ");
	let format = explode.map((element) =>{
		return  element.charAt(0).toUpperCase() + element.substring(1).toLowerCase() ;
	});
	return format.join(" ");
};


export const checkEmail = (email: string) => {
	const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/;
	return reg.test(email);
}

export const cutString = (str: string, length?: number) => {
	let strLen = length || 45;
	return `${str.substring(0, strLen)}...`;
}

/**
 * characters mask
 * @param {string} string
 * @param {string} insertNumber
 */
export const stringConvert = (string: string, insertNumber?: number) => {
	if (insertNumber)
		return string.replace(/[^a-zA-ZáàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ0-9 ]/g, '');
	else
		return string.replace(/[^a-zA-ZáàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ ]/g, '');
}


/**
 * money mask
 * @param {*} money
 */
export const moneyFormat = (money: any) => {
	money = String(money);
	money = money.replace(/\D/g,"");
	if (money.length <= 4){
		money = money.replace(/^(\d{1,3})(\d{4})$/,"$1.$2");
	} else if (money.length <= 5){
		money = money.replace(/^(\d{2,3})(\d{2})$/,"$1.$2");
	} else if (money.length <= 7){
		money = money.replace(/^(\d{1,2})(\d{3})(\d{2})$/,"$1,$2.$3");
	} else {
		money = money.replace(/^(\d{3})(\d{3})(\d{2})$/,"$1,$2.$3");
	}
	let signal = money.length >= 3 ? "$ " : "";
	return signal + money;
}

/**
 * @param {*} date
 */
export const weekday = (date: string) => {
	return new Date(date).toLocaleString("en", { weekday: "long" })
}


/**
 * date mask
 * @param {*} date
 */
export const dateFormat = (date: any) => {
	date = String(date);
	if (date.length > 2 && date.length < 4 || date.length > 5 && date.length < 7)
		date = date.replace(/\D/g,"");
	date = date.replace(/^(\d{2})(\d{2})(\d{0,4})$/,"$1/$2/$3");

	return date;
}

/**
 * number mask
 * @param {number} number
 */
export const maskNumber = (number: string) => {
	number = number.replace(/\D/g,"");
	number = number.replace(/^(\d{10})$/,"$1");
	return number;
}

/**
 * cellphone mask
 * @param {*} cellPhone
 */
export const cellPhoneFormat = (cellPhone: any) => {
	if (cellPhone.length > 10 && cellPhone.length < 12)
		cellPhone = cellPhone.replace(/\D/g,"");
	cellPhone = cellPhone.replace(/^(\d{2})(\d{4,5})(\d{0,4})$/,"($1) $2-$3");
	return cellPhone;
}

export const cellPhoneStrFormat = (cellPhone: string) => {
	return cellPhone.replace(/^(\d{2})(\d{4,5})(\d{0,4})$/,"($1) $2-$3");
}

export const phoneFormat = (phone: string | any) => {
	if (phone.length > 10 && phone.length < 12)
		phone = phone.replace(/\D/g,"");
	phone = phone.replace(/^(\d{2})(\d{4})(\d{0,4})$/,"($1) $2-$3");
	return phone;
};


/**
 * convert data to string
 * @param {*} time
 */
export const timeToString = (time: any) => {
	let date = new Date(time);
	const offset = date.getTimezoneOffset()
	date.setDate(date.getDate() + 1);
	date = new Date(date.getTime() - (offset*60*1000));
	return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
}

export const afterToday = () => {
	let date = new Date();
	const offset = date.getTimezoneOffset()
	date.setDate(date.getDate() + 1);
	date = new Date(date.getTime() - (offset*60*1000));
	const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
	const m = date.getMonth() + 1;
	const month = m > 9 ? m : '0' + m;
	return `${date.getFullYear()}/${month}/${day}`
}

export const weekdayTomorrow = () => {
	let date = new Date();
	const offset = date.getTimezoneOffset()
	date.setDate(date.getDate() + 1);
	date = new Date(date.getTime() - (offset*60*1000));
	const weekNumber = date.getDay();
	return weekDays[weekNumber];
}

export const smallerThenTomorrow = (cDate: string) => {
	let date = new Date();
	const offset = date.getTimezoneOffset();
	date.setDate(date.getDate() + 1);
	date = new Date(date.getTime() - (offset*60*1000));
	const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
	const m = date.getMonth() + 1;
	const month = m > 9 ? m : '0' + m;

	const comboDate = cDate.split('/');
	const cDay = comboDate[2];
	const cMonth = comboDate[1];
	const cYear = comboDate[0];

	const matCombo = Number(cDay) + Number(cMonth) + Number(cYear);
	const matDate = Number(day) + Number(month) + Number(date.getFullYear());

	return matCombo <= matDate;
}

export const smallerThenToday = (cDate: string) => {
	let date = new Date();
	const offset = date.getTimezoneOffset();
	date = new Date(date.getTime() - (offset*60*1000));
	const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
	const m = date.getMonth() + 1;
	const month = m > 9 ? m : '0' + m;

	const comboDate = cDate.split('/');
	const cDay = comboDate[2];
	const cMonth = comboDate[1];
	const cYear = comboDate[0];

	const matCombo = Number(cDay) + Number(cMonth) + Number(cYear);
	const matDate = Number(day) + Number(month) + Number(date.getFullYear());

	return matCombo <= matDate;
}

export const weekdayToday = () => {
	let date = new Date();
	const offset = date.getTimezoneOffset();
	date = new Date(date.getTime() - (offset*60*1000));
	const weekNumber = date.getDay();
	return weekDays[weekNumber];
}

/**
 * systemDate - return date
 */
export const today = () => {
	let today = new Date();
	const offset = today.getTimezoneOffset()
	today.setDate(today.getDate() + 1);
	today = new Date(today.getTime() - (offset*60*1000));
	const day = today.getDate() > 9 ? today.getDate() : '0' + today.getDate();
	const m = today.getMonth() + 1;
	const month = m > 9 ? m : '0' + m;
	return `${today.getFullYear()}-${month}-${day}`
}

export const cpfFormat = (cpf: any) => {
	cpf = cpf.replace(/[^0-9]+/g, "");
	if(cpf.length > 3 ){
		cpf = cpf.substring(0,3) + "." + cpf.substring(3);
	}
	if(cpf.length > 7 ){
		cpf = cpf.substring(0,7) + "." + cpf.substring(7);
	}
	if(cpf.length > 11){
		cpf = cpf.substring(0,11) + "-" + cpf.substring(11,13);
	}
	return cpf;
};

type EmojiType = 'error' | 'success' | 'running' | 'bug' | 'broken' | 'burn' | any;

export const Logger = (log?: any, arg1?:any, arg2?:any, emoji?: EmojiType) => {
	let logType: any;
	switch (emoji) {
		case 'error' :
			logType = '0x1f6a8';
			break;
		case 'success' :
			logType = '0x1f680';
			break;
		case 'running' :
			logType = '0x1f69a';
			break;
		case 'bug' :
			logType = '0x1f41e';
			break;
		case 'broken' :
			logType = '0x1f6a7';
			break;
		case 'burn' :
			logType = '0x1f525';
			break;
		default:
			logType = '0x1f913';
			break;
	}

	return console.log(`>> ${String.fromCodePoint(logType)}`, log, arg1, arg2);
}
