import React, { createContext, useContext, useState } from 'react';

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

const StateContext = createContext({
  currentColor: '#03C9D7',
  setThemeSettings: (bool: boolean) => bool,
  themeSettings: false,
  activeMenu: true,
  currentMode: 'Light',
  setCurrentMode: (e: string) => e,
  setCurrentColor: (e: string) => e,
  setMode: (e: any) => e,
  setColor: (e: any) => e,
  screenSize: 900,
  setScreenSize: (e: any) => e,
  setActiveMenu: (bool: boolean) => bool,
  isClicked: {
    userProfile: false,
    notification: false,
    cart: false,
    chat: false,
  },
  handleClick: (e: any) => e,
  initialState,
  setIsClicked: (init: any) => init,
});

export const ContextProvider = ({ children }: any) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState('#03C9D7');
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);

  const setMode = (e: any) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
  };

  const setColor = (color: any) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
  };

  const handleClick = (clicked: any) => {
    return setIsClicked({...initialState, [clicked]: true});
  };

  return (
    // @ts-ignore
    <StateContext.Provider value={{ currentColor, currentMode, activeMenu, screenSize, setScreenSize, handleClick, isClicked, initialState, setIsClicked, setActiveMenu, setCurrentColor, setCurrentMode, setMode, setColor, themeSettings, setThemeSettings }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
