import React from "react";
import {Colors} from "../../styles";
import {Assets} from "../../assets";

export const Privacy = () => {

	return (
		<div className="h-full">
			<div style={styles.header}>
				<div className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
					<img src={Assets.logo} width={50} height={50} alt={'logo'}/>
					<span style={{color: Colors.light}} >Clean4u</span>
				</div>
			</div>
				<div
					style={styles.content}
					className="flex relative h-full w-full">
					<h2
						style={styles.title}
						className="mt-6 text-3xl tracking-tight font-bold text-gray-900">
						Privacy Policy
					</h2>
					<p style={styles.p}>
						HOME CLEAN4U (CLEAN4U) - https://homeclean4u.net, presents to its users in general its PRIVACY POLICY.
					</p>
					<p style={styles.p}>
						This document governs the way in which CLEAN4U will take care of the PERSONAL DATA captured and stored, when using the available software/application.
					</p>
					<p style={styles.p}>
						Before reading this document, make sure you have accessed and agreed to our TERMS AND CONDITIONS OF USE at https://homeclean4u,net/terms
					</p>
					<p style={styles.p}>
						It is important to note that if you register, access and/or use the CLEAN4U software/application in any way or for any course of time, including browsing, viewing, downloading content, uploading data, generating, receiving and/or transmitting any data, information or messages to/from the system will be subject to this PRIVACY POLICY.
					</p>
					<p style={styles.p}>
						If you need any further clarification on how we handle your personal data, please do not hesitate to contact us electronically: contact@homeclean4u.net.
					</p>
					<p style={styles.p}>
						1) GENERAL CONCEPTS
					</p>
					<p style={styles.p}>
						So that you can better understand the way in which we take care of your PERSONAL DATA, let's establish some basic concepts:
					</p>
					<p style={styles.p}>
						a. PERSONAL DATA: information related to an identified or identifiable natural person;
					</p>
					<p style={styles.p}>
						b. SENSITIVE PERSONAL DATA: PERSONAL DATA about racial or ethnic origin, religious conviction, political opinion, membership of a trade union or organization of a religious, philosophical or political nature, data relating to health or sex life, genetic or biometric data, when linked to a natural person;
					</p>
					<p style={styles.p}>
						c. DIGITAL IDENTIFICATION DATA: are those related to the IP address, the record of interactions with the platform, installed applications and cookies;
					</p>
					<p style={styles.p}>
						d. ACCOUNT: is the individual and non-transferable profile, which allows the CLIENT to make use of the CLEAN4U platform, in addition to viewing and updating their personal data, preferences and data, as well as communicating with the CLEAN4U service/support team through functionality from contact us;
					</p>
					<p style={styles.p}>
						e. DATABASE: is the set of personal and sensitive data, established in one or several places, in electronic or physical support, exclusively owned by CLEAN4U;
					</p>
					<p style={styles.p}>
						f. HOLDER: is the natural person to whom the data being processed;
					</p>
					<p style={styles.p}>
						g. TREATMENT: any operation carried out with PERSONAL DATA, such as those referring to the collection, production, reception, classification, use, access, reproduction, transmission, distribution, processing, archiving, storage, elimination, evaluation or control of information, modification, communication, transfer, diffusion or extraction;
					</p>
					<p style={styles.p}>
						h. CONSENT: is the free, informed and unequivocal expression by which the holder agrees to the processing of his PERSONAL DATA for a specific purpose;
					</p>
					<p style={styles.p}>
						i. FREE ACCESS: it is the guarantee to the holders of facilitated and free consultation on the form and duration of data processing, as well as on the completeness of their personal data;
					</p>
					<p style={styles.p}>
						j. IP: is the set of numbers that identifies the computer or mobile device used to access the platform;
					</p>
					<p style={styles.p}>
						k. COOKIES: are files sent by the platform's server to the user's computer or mobile device, with the purpose of identifying and obtaining access data, such as browsed pages or clicked links, thus allowing the personalization of the use of the website of the platform;
					</p>
					<p style={styles.p}>
						2) HOW DO WE USE YOUR PERSONAL DATA?
					</p>
					<p style={styles.p}>
						CLEAN4U is a technology company that provides residential cleaning services.
					</p>
					<p style={styles.p}>
						Therefore, when you access our marketplace, either via the website or through the application, or even when you contact us through our other service channels, CLEAN4U's servers capture your PERSONAL DATA for the exclusive purpose of offering the best possible experience so that you can better interact with all the features available.
					</p>
					<p style={styles.p}>
						Likewise, when you register on our platform, PERSONAL DATA such as full name, email, telephone number, full address and other related data are requested.
					</p>
					<p style={styles.p}>
						CLEAN4U also archives in its DATABASES all conversations held through the available contact us functionality, whether referring to doubts about using the platform, or referring to conversations held with our team.
					</p>
					<p style={styles.p}>
						Internally, all PERSONAL DATA collected is accessed only by duly authorized employees, always respecting the principles of proportionality, necessity and relevance for CLEAN4U's objectives, in addition to the firm commitment to confidentiality and preservation of privacy. In addition, CLEAN4U is committed to adopting all the most advanced security measures to protect the PERSONAL DATA collected.
					</p>
					<p style={styles.p}>
						CLEAN4U also reserves the right to send advertising messages through its website
					</p>
				</div>
		</div>
	)
}

const styles = {
	content: {
		flex: 1,
		marginBottom: 100,
		height: '100%',
		flexDirection: "column",
		backgroundColor: Colors.light,
		padding: 50,
	},
	header: {
		display: "flex",
		height: 90,
		backgroundColor: Colors.green_dark,
		alignContent: "center",
		alignItems: "center",
	},
	title: {
		marginTop: 20,
		marginBottom: 50,
	},
	p: {
		margin: 10,
	}
} as const;
