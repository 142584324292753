import {moneyFormat} from "../../../utils";
import {BsCurrencyDollar} from "react-icons/bs";
import {Button} from "../../../components";
import React from "react";
import {ComboStore} from "../../../stores/combo/comboStore";
import {useStateContext} from "../../../contexts/ContextProvider";
import {useNavigate} from "react-router-dom";

export const DailyAmount = () => {
	const navigation = useNavigate();
	const {currentColor} = useStateContext();
	const { setTotalCombosValue } = ComboStore();

	return (
		<div
			className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
			<div className="flex justify-between items-center">
				<div>
					<p className="font-bold text-gray-400">Total do dia</p>
					<p
						className="text-2xl">{Number(setTotalCombosValue()) > 0 ? moneyFormat(setTotalCombosValue()) : '$ 0.00'}</p>
				</div>
				<button
					type="button"
					style={{backgroundColor: currentColor}}
					className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
				>
					<BsCurrencyDollar/>
				</button>
			</div>
			<div className="mt-6">
				<Button
					callback={() => navigation('/orders')}
					color="white"
					bgColor={currentColor}
					text="Lista de Combos"
					borderRadius="10px"
				/>
			</div>
		</div>
	);
}
