const product5 = require("../../assets/img/product5.jpg");
const product6 = require("../../assets/img/product6.jpg");
const product7 = require("../../assets/img/product7.jpg");

export const cartData = [
  {
    image: product5,
    name: 'butterscotch ice-cream',
    category: 'Milk product',
    price: '$250',
  },
  {
    image: product6,
    name: 'Supreme fresh tomato',
    category: 'Vegetable Item',
    price: '$450',
  },
  {
    image: product7,
    name: 'Red color candy',
    category: 'Food Item',
    price: '$190',
  },
];
